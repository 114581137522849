
import './App.css';
import Router from './route/Router';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

function App() {

  return (
    <ReactLenis
      root
      options={{ gestureOrientataion: "both" }}
    >
      <HelmetProvider>
        <div className="App">
          <ToastContainer />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </div>
      </HelmetProvider>
    </ReactLenis>
  );
}

export default App;
