import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../assets/images/download_banner.jpg'
import bgImage1 from '../../assets/images/demo-application-how-it-works-bg-01.jpg'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { Swiper, SwiperSlide } from 'swiper/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { EffectCards, Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';

export default function Download() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        function getMobileOperatingSystem() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return "Android";
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "Unknown";
        }

        // Redirect based on the detected OS
        const os = getMobileOperatingSystem();
        if (os === "Android") {
            // window.location.href = "https://play.google.com/store/apps/details?id=com.praarthana";
        } else if (os === "iOS") {
            // window.location.href = "https://apps.apple.com/in/app/praarthana/id1638860087";
        } else {
            // Redirect to a generic page if the OS is unknown
            // window.location.href = "https://praarthana.app/";
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Download | Praarthana App</title>
                <meta name="description" content="Download the Praarthana app and embark on a spiritual journey. Access a rich library of prayers and hymns, receive daily inspiration, and connect with a community of like-minded individuals." />
                <meta name="keywords" content="Praarthana, prayer app, spiritual journey, prayers, hymns, daily inspiration, spiritual growth" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Praarthana App - Your Personal Prayer Companion" />
                <meta property="og:description" content="Experience the spiritual journey with Praarthana. Access a wide range of prayers and hymns, receive daily inspiration, and connect with a supportive community." />
                <meta property="og:url" content="https://www.praarthana.app" />
                <meta property="og:image" content="https://www.praarthana.app/og.png" />
                <meta property="og:location" content="India" />
            </Helmet>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-8 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Join the Praarthana App</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Download Now</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0">
                <div class="container download-section">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-6 col-md-10 left-section text-center position-relative animation-float md-mb-30px" data-anime='{ "effect": "slide", "color": "#ffffff", "direction":"lr", "easing": "easeOutQuad", "delay":50}'>
                            <img src={require('../../assets/image/prarthna_home_img01.png')} alt="prarthna_home_img01" />
                        </div>
                        <div class="col-xl-5 offset-xl-1 col-lg-6 col-md-9 position-relative text-center text-lg-start" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <div class="bg-base-color d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">Immerse your soul in god</div>
                            <h4 class="fw-700 text-dark-gray w-90 xl-w-100 ls-minus-1px">Get in touch with Spirituality.</h4>
                            <p class="w-85 xs-w-100 mx-auto mx-lg-0">Experience the spiritual journey with Praarthana, your personal prayer companion. Whether you're at home or on the go, Praarthana brings a rich collection of prayers, hymns, and spiritual content right to your fingertips.</p>
                            <div class="row mb-35px pe-10 md-ps-25px md-pe-25px sm-px-0 justify-content-center justify-content-lg-start">
                                <div class="col-6 col-lg-6 col-sm-5">
                                    <a href="https://play.google.com/store/apps/details?id=com.praarthana" target='_blank' class="d-block transition-inner-all">
                                        <img src={require("../../assets/image/play-store-white.png")} class="box-shadow-double-large-hover" alt="" />
                                    </a>
                                </div>
                                <div class="col-6 col-lg-6 col-sm-5">
                                    <a href="https://apps.apple.com/in/app/praarthana/id1638860087" target='_blank' class="d-block transition-inner-all">
                                        <img src={require("../../assets/image/app-store-white.png")} class="box-shadow-double-large-hover" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="fw-500 text-dark-gray"><img src={require('../../assets/image/downloads.jpg')} class="me-10px" alt="" /><span class="fw-800 text-decoration-line-bottom">50000+</span> people already subscribed.</div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0 overflow-hidden sm-pb-17 xs-pb-25">
                <div class="container-fluid ps-10 xxl-ps-6 sm-ps-30px sm-pe-30px">
                    <div class="row justify-content-center">
                        <div class="col-12 cover-background overflow-visible ps-8 pe-8 xxl-ps-5 xxl-pe-5 lg-ps-4 lg-pe-4 pt-8 xxl-pt-6 lg-pt-4 xl-pb-2 md-p-8 md-pb-3 border-radius-top-left sm-border-radius-all" style={{ backgroundImage: `url(${bgImage1})` }}>
                            <div class="row">
                                <div class="col-xl-4 col-lg-5 position-relative mt-40px xxl-mt-10px lg-mt-40px md-mt-0 md-mb-50px text-center text-lg-start" data-anime='{"el": "childs",  "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'>
                                    <div class="bg-white-transparent-very-light d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">App presentation</div>
                                    <h3 class="fw-600 text-white w-90 xl-w-100 ls-minus-1px">How to Praarthana App works.</h3>
                                    <p class="w-85 xl-w-100 mb-40px md-mb-30px">Experience the spiritual journey with Praarthana, your personal prayer companion. Whether you're at home or on the go, Praarthana brings a rich collection of prayers, hymns, and spiritual content right to your fingertips.</p>

                                </div>
                                <div class="col-xl-8 col-lg-7" data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'>
                                    <div class="outside-box-right-10 md-outside-box-right-20 sm-outside-box-right-0 margin-minus-95px-bottom md-margin-minus-60px-bottom">

                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={10}

                                            breakpoints={{
                                                640: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                },
                                                768: {
                                                    slidesPerView: 2.4,
                                                    spaceBetween: 20,
                                                },
                                                1024: {
                                                    slidesPerView: 3.6

                                                    ,
                                                    spaceBetween: 20,
                                                },
                                            }}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            modules={[Autoplay]}
                                            loop={true}
                                            className="mySwiper  slider-one-slide magic-cursor drag-cursor"
                                        >
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/1.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/2.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/3.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/5.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/6.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/7.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>
                                            <SwiperSlide className='text-center'>
                                                <img src={require('../../assets/image/phone-screen/1.png')} class="w-100" alt="praarthana" />
                                            </SwiperSlide>

                                        </Swiper>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
