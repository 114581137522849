import React from 'react'
// import './style.css'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { get, post } from '../../helpers/helper_api';
import { AvForm, AvField } from "availity-reactstrap-validation";
// import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../component/Loader';
import { Helmet } from 'react-helmet-async';
import moment from "moment";
import bgImage from "../../assets/images/download_banner.jpg";

export default function BlogDetails(props) {
	const params = useParams();
	const location = useLocation();
	const user = null;
	let navigate = useNavigate();
	const [propertydetail, setPropertydetail] = useState(null);
	const [blogUrl, setBlogUrl] = useState(undefined);
	const [homedata, setHomedata] = useState(null);
	const [catList, setCatList] = useState(null);
	const [tags, setTags] = useState([]);
	const [nextBlog, setNextBlog] = useState(undefined);
	const [prevBlog, setPrevBlog] = useState(undefined);
	const [similer, setSimiler] = useState([]);
	const [desc, setDesc] = useState("");
	const [data, setData] = useState([]);
	const [suggested, setSuggested] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingMoreStories, setLoadingMoreStories] = useState(false);
	const [scrollLoadHeight, setScrollLoadHeight] = useState(undefined);

	useEffect(() => {
		window.scrollTo(0, 0);
		setBlogUrl(params?.url);
	}, [params])

	useEffect(() => {
		if (blogUrl)
			getBlog();
	}, [blogUrl]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, propertydetail, data, scrollLoadHeight]);

	const getBlog = () => {
		// setLoader(true);
		get('blog/title?title=' + blogUrl).then(async (json) => {
			// console.log('bank detail',json)
			// console.log('propertyId detail', json?.data);
			setLoading(false);
			if (json?.statusCode == 200) {
				setPropertydetail(json?.data);
				setSuggested(json?.suggested);
				setNextBlog(json?.next);
				setPrevBlog(json?.prev);
				setSimiler(json?.similer);
			}
			//   setLoader(false);
		}).catch((err) => {
			console.log(err);
			setLoading(false);
			//   setLoader(false);
		})
		get('blog/category/web').then(async (json) => {
			// console.log('bank detail',json)
			// console.log('propertyId detail', json?.data);
			if (json?.statusCode == 200) {
				setCatList(json?.data);
			}
			//   setLoader(false);
		}).catch((err) => {
			console.log(err)
			//   setLoader(false);
		})
		get('tags/web').then(async (json) => {
			// console.log('bank detail',json)
			// console.log('propertyId detail', json?.data);
			if (json?.statusCode == 200) {
				setTags(json?.data);
			}
			//   setLoader(false);
		}).catch((err) => {
			console.log(err)
			//   setLoader(false);
		})
	}

	const getBlogs = (cp) => {
		setLoadingMoreStories(true);
		if (!loadingMoreStories) {
			post("/blog/related", { currentPage: cp })
				.then(res => {
					setLoadingMoreStories(false);
					if (res?.statusCode == 200) {
						let temp = cp == 0 ? [] : Object.assign([], data);
						console.log('data to set', [...temp, ...res?.data]);
						setData([...temp, ...res?.data]);
						setSuggested(res?.suggested);
						setTotalPage(res?.totalPage);
						setCurrentPage(res?.currentPage + 1);
					} else {
						toast.error("We are having a problem to load the blog, Please Try again later!")
					}
				})
				.catch(err => {
					setLoadingMoreStories(false);
					console.log('error while getting blogs', err);
					toast.error("We are having a problem to load the blog, Please Try again later!")
				});
		}
	}

	const openBlog = (item) => {
		return "/blog/" + item?.link;
	}

	const openCat = (item) => {
		if (item?._id)
			return '/blogs/category/' + parseName(item?.title)
	}

	const openTag = (item) => {
		if (item?._id)
			return '/blogs/tag/' + parseName(item?.title)
	}

	const openExp = (item) => {
		return '/experience/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name);
	}

	const openCountry = (item) => {
		return `/country/${compressId(item?.countryId)}/top-travel-destinations-in-${parseName(item?.continent[0]?.name)}-${item?.country[0]?.name}`;
	}

	const openProp = (item) => {
		return "/destination/" + compressId(item?._id) + "/" + parseName(item?.name) + "-in-" +
			parseName(item?.country[0]?.name) + "-" +
			parseName(item?.continent[0]?.name);
	}

	const compressId = (id) => {
		const temp = id.slice(id.length - 4, id.length);
		// console.log('compressing id', temp);
		return temp;
	}

	const parseName = (str) => {
		if (str) {
			let temp = str.split(",").join("");
			return temp.split(" ").join("-").toLowerCase();
		} else
			return "";
	}

	const handleSubmitComment = (e, v) => {
		console.log('commenting', v);
		if (user) {
			let body = {
				...v,
				blogId: propertydetail?._id,
				token: user?.token,
			}
			console.log('sending reviews', body)
			post('/review/add', body)
				.then((res => {
					console.log('response from adding review', res);
					if (res?.statusCode == 200) {
						toast.success(res?.message);
						getBlog();
						setDesc("");
					}
					else if (res?.statusCode == 208) {
						toast.error(res?.error);
					}
					else
						toast.error(res?.error);
				}))
				.catch(err => {
					console.log('error whiling adding rating', err);
					toast.error('Something went wrong!');
				})
		} else
			navigate("/login");
	}

	const handleValidInquiry = (e, v) => {
		const body = {
			...v
		}
		post('/query/add', body)
			.then((res => {
				console.log('response from subscribe');
				if (res?.statusCode == 200)
					toast.success(res?.message);
				else
					toast.error(res?.error);
			}))
			.catch(error => {
				console.log('error while subscribing', error);
				toast.error('Something went wrong');
			})
	}

	const handleScroll = () => {
		let divHeight = document.getElementById("scrollableView")?.scrollHeight;
		let divDescHeight = document.getElementById("desc")?.scrollHeight;
		if (!scrollLoadHeight)
			setScrollLoadHeight(divDescHeight);
		console.log('scrolling', window.scrollY, divHeight, divDescHeight, scrollLoadHeight);
		if (window?.scrollY < (scrollLoadHeight - 652))
			if ((window?.scrollY - divHeight) > 544)
				if (totalPage > currentPage)
					getBlogs(currentPage);
	}

	return (
		<>
			{
				loading && <Loader />
			}
			{propertydetail ?
				<Helmet>
					<title>{propertydetail?.metaTitle}</title>
					<meta name="keyword" content={propertydetail?.metaKey} />
					<meta name="description" content={propertydetail?.metaDesc} />
					<meta name="dc.language" content="English" />
					<meta name="dc.source" content={`https://praarthana.app/${location?.pathname}`} />
					<meta name="dc.title" content={propertydetail?.metaTitle} />
					<meta name="dc.keywords" content={propertydetail?.metaKey} />
					<meta name="dc.description" content={propertydetail?.metaDesc} />
					{/* <meta name="Author" content={propertydetail?.author[0]?.title} /> */}
					<meta name="robots" content="index, follow" />
					<meta property="og:locale" content="en-in" />
					<meta property="og:type" content="website" />
					<meta property="og:site_name" content="Praarthana App" />
					<meta name="og:title" content={propertydetail?.metaTitle} />
					<meta name="og:description" content={propertydetail?.metaDesc} />
					<meta name="og:image" content={propertydetail?.banner} />
					<meta property="og:image:width" content="400" />
					<meta property="og:image:height" content="400" />
					<meta property="og:image:type" content="image/png" />
					<meta name="theme-color" content="#ffffff" />

					<meta name="twitter:title" content={propertydetail?.metaTitle} />
					<meta name="twitter:card" content="summary" />
					<meta name="twitter:site" content="@praarthana_app" />
					<meta name="twitter:description" content={propertydetail?.metaDesc} />
					<meta name="twitter:image" content={propertydetail?.banner} />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<link rel="canonical" href={`https://praarthana.app/${location?.pathname}`} />
					<script type="application/ld+json">
						{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Article",
						"mainEntityOfPage": {
							"@type": "WebPage",
							"@id": "https://praarthana.app/${location?.pathname}"
						  },
                        "headline": "${propertydetail?.metaTitle}",
						"description":"${propertydetail?.metaDesc}",
						"image": "${propertydetail?.banner}",
						"author": {
							"@type": "Person",
							"name": "Praarthana"
						  },  
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "${5}",
                            "bestRating": "5",
                            "ratingCount": "${propertydetail?.reviews?.length}"
                        },
						"publisher": {
							"@type": "Organization",
							"name": "Woow Destinations",
							"logo": {
							  "@type": "ImageObject",
							  "url": "https://praarthana.app/logo.png"
							}
						  },
						  "datePublished": "${propertydetail?.createdAt}",
						  "dateModified": "${propertydetail?.updatedAt}"
                    }
                `}
					</script>
				</Helmet>
				: null}
			<section class="page-title-big-typography pb-0 cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>

				<div class="container">
					<div class="row align-items-center justify-content-center extra-very-small-screen">
						<div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>

							<h1 class="mb-0 text-dark-gray f3-700 ls-minus-2px">Blog</h1>
							<h3 class="mt-15px fs-16px fw-500 text-dark-gray mobile-h3">{propertydetail?.title}</h3>
						</div>
					</div>
				</div>
			</section>
			<section className="blog-section blog_section_main blog_section_main_detail pb-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-8" id="desc">
							<div className="single-post">
								<div className="single-post-content">
									<div className="post-content">
										<div className="post-social holder-box">
											<span>Share</span>
											<ul className="share-post">
												<li><a href="https://www.facebook.com/HinduPraarthana/" className="facebook"><i className="fab fa-facebook"></i></a></li>
												<li><a href="https://www.youtube.com/@praarthana_app" className="Youtube"><i class="fa-brands fa-youtube"></i></a></li>
												{/* <li><a href="#" className="pinterest"><i className="fab fa-pinterest"></i></a></li> */}
												<li><a href='https://www.instagram.com/praarthana_app/' target='_blank'><i class="icon feather icon-feather-instagram icon-small text-medium-gray text-dark"></i></a></li>
											</ul>
										</div>
										<div className="post-content-text">
											<Link className="text-link" target='_blank' to={openCat(propertydetail?.category[0])}>
												{/* <span style={{ color: '#e74c3c' }}>By</span>   */}
												{propertydetail?.category[0]?.title}
											</Link>
											<h1 style={{ textTransform: 'capitalize' }}>{propertydetail?.title}</h1>
											<ul className="post-tags">
												<li>Last Updated : {moment(propertydetail?.updatedAt).format("MMM DD, YYYY")}</li>
												{/* <li>{propertydetail?.reviews?.length} comments</li> */}
											</ul>
											<img src={propertydetail?.image} alt={propertydetail?.title} style={{ marginBottom: '10px' }} />
											<h2 style={{ textTransform: 'capitalize' }}>
												{propertydetail?.title}
											</h2>
											<div className='description_section' dangerouslySetInnerHTML={{ __html: propertydetail?.desc }}></div>
											<div className="share-tags-box mb-4">
												<ul className="tags">
													{
														propertydetail?.tags?.map((item, index) => {
															return (
																<li key={index}><Link target='_blank' to={openTag(item)}>{item?.title}</Link></li>
															)
														})
													}
												</ul>

											</div>
										</div>
									</div>
									<br />
									<br />
									<div className="prev-next-box">
										<div className="prev-box">
											{/* {prevBlog?.length > 0 &&
												<div className='pagination_box'>
													<div className='circle_img'>
														<img src={prevBlog[0]?.image} alt={prevBlog[0]?.titleShort} />
													</div>
													<div className='left_nbtn_box'>
														<Link target='_blank' className="text-link" to={openBlog(prevBlog[0])}><i className="fa fa-angle-left"></i> Previous Post</Link>
														<h2><Link to={openBlog(prevBlog[0])}>{prevBlog[0]?.titleShort}</Link></h2>
													</div>

												</div>
											} */}
										</div>
										<div className="next-box">
											{/* {nextBlog?.length > 0 &&
												<div className='pagination_box_p'>

													<div className='left_nbtn_box'>
														<Link target='_blank' className="text-link next-link" to={openBlog(nextBlog[0])}>Next Post <i className="fa fa-angle-right"></i></Link>
														<h2><Link target='_blank' to={openBlog(nextBlog[0])}>{nextBlog[0]?.titleShort}</Link></h2>
													</div>
													<div className='circle_img'>
														<img src={nextBlog[0]?.image} alt={nextBlog[0]?.titleShort} />
													</div>
												</div>
											} */}
										</div>
									</div>
								</div>
								{/* <div className="advertise-box">
								<a href="#"><img src="http://nunforest.com/mite-demo/upload/banners/ban3.jpg" alt="" /></a>
							</div> */}
							</div>
						</div>
						<div className="col-lg-4" id="right">
							<div className="sidebar">
								<div className="widget social-widget">
									<h2>Social</h2>
									<ul className="social-list">
										<li>
											<a href="https://www.facebook.com/HinduPraarthana/" target='_blank'>
												<i className="fab fa-facebook"></i>
												facebook
											</a>
										</li>
										<li>
											<a href="https://www.youtube.com/@praarthana_app">
												<i class="fa-brands fa-youtube"></i>
												youtube
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/praarthana_app/" target='_blank'>
												<i className="fab fa-instagram"></i>
												instagram
											</a>
										</li>
									</ul>
								</div>
								<div className=''>
									<div className="widget category-widget">
										<h2>Categories</h2>
										<ul className="category-list list_posts_tag">
											{
												catList?.map((item, index) => {
													return (
														<Link target='_blank' key={index} to={openCat(item)}>
															{item?.title}
														</Link>
													)
												})
											}
										</ul>
									</div>
									<div className="widget list-widget">
										<h2>Popular mentions</h2>
										<ul className="list-posts list_posts_tag">
											{
												tags?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" key={index} to={openTag(item)}>{item?.title}</Link>
													)
												})
											}
										</ul>
									</div>
								</div>
								{/* <div className="widget subscribe-widget2">
									<h2>Join Our Newsletter</h2>
									<p>Sign up for our free newsletters to receive the latest news. Don't worry we won't do spam.</p>
									<AvForm onValidSubmit={handleValidInquiry} className="subscibe-form">
										<AvField
											type="email"
											name="email"
											// className="rlr-subscribe__input rlr_subscribe__input_new"
											placeholder="Enter your email"
											required
										/>
										<button type='submit' id="submit" className="btn" >Subscribe</button>
									</AvForm>
								</div> */}
								<div className="widget list-widget mt-3" id="scrollableView">
									<h2>Latest Stories</h2>
									<ul className="list-posts">
										{suggested?.map((item, index) => {
											return (
												<li key={index} className='list_'>
													<div>
														<Link target='_blank' className="text-link text_link_" to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link>
													</div>
													<h2><Link target='_blank' to={openBlog(item)}>{item?.title}</Link></h2>
													<ul className="post-tags">
														<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
														{item?.tags?.map((tag) => (
															<li><Link target="_blank" to={openTag(tag)}>{tag?.title}</Link></li>
														))}
													</ul>
												</li>
											)
										})
										}
									</ul>
									{/* {currentPage < (totalPage / 12).toFixed(0) && loadingMoreStories &&
										<div className="center-button">
											<a className="button-one" >Loading...</a>
										</div>
									} */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className='row'>
						<div className="single-post">
							<div className="single-post-content">
								{similer?.length > 0 &&
									<div className="related-box">
										<h2>Related Posts</h2>
										<div className="row">
											{similer?.map((item) => (
												<div className="col-lg-4 col-md-4">
													<div className="news-post standard-post text-left">
														<div className="image-holder">
															<Link target='_blank' to={openBlog(item)}><img src={item?.image} alt={item?.titleShort} /></Link>
														</div>
														{/* <Link className="text-link" to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link> */}
														<h2><Link target='_blank' to={openBlog(item)}>{item?.titleShort}</Link></h2>
														<ul className="post-tags">
															<li>by {item?.author[0]?.title}</li>
															<li>{item?.updatedAt}</li>
														</ul>
													</div>
												</div>
											))}
										</div>
									</div>
								}
								{propertydetail?.reviews?.length > 0 &&
									<div className="comments">
										<h2 className="comments-title">
											{propertydetail?.reviews?.length} Comments
										</h2>
										<ul className="comments__list">
											{propertydetail?.reviews?.map((item) => (
												<li className="comments__list-item">
													{item?.user?.length > 0 ?
														<img className="comments__list-item-image" src={item?.user[0]?.profile_picture ? item?.user[0]?.profile_picture : "http://nunforest.com/mite-demo/upload/single/th1.jpg"} alt={item?.user[0]?.name} />
														:
														<img className="comments__list-item-image" src={item?.avatar ? item?.avatar : "http://nunforest.com/mite-demo/upload/single/th1.jpg"} alt="" />
													}
													<div className="comments__list-item-content">
														{item?.user?.length > 0 ?
															<h3 className="comments__list-item-title">
																{item?.user[0]?.name}
															</h3>
															:
															<h3 className="comments__list-item-title">
																{item?.userName}
															</h3>
														}
														<span className="comments__list-item-date">
															Posted {item?.createdAt}
														</span>
														{/* <a className="comments__list-item-reply" href="#">
														<i className="la la-mail-forward"></i>
														Reply
													</a> */}
														<p className="comments__list-item-description">
															{item?.desc}
														</p>
													</div>
												</li>
											))}
										</ul>
									</div>
								}
								{propertydetail?.reviews?.findIndex(element => element?.userId == user?._id) == -1 &&
									<AvForm onValidSubmit={handleSubmitComment} className="contact-form" id="comment-form">
										<h2 className="contact-form__title">
											Write a Comment
										</h2>
										{/* <div className="row">
									<div className="col-md-6">
										<input className="contact-form__input-text" type="text" name="name" id="name" placeholder="Name:" />
									</div>
									<div className="col-md-6">
										<input className="contact-form__input-text" type="text" name="mail" id="mail" placeholder="Email:" />
									</div>
								</div> */}
										<AvField
											className="contact-form__textarea"
											name="desc"
											id="comment"
											placeholder="Comment"
											required
											type="textarea"
											value={desc}
											onChange={(e => setDesc(e.target.value))}
											rows={4}
										/>
										<button className="contact-form__submit"
											type="submit"
											name="submit-contact"
											id="submit_contact"
											value="Send Comment">Submit</button>
									</AvForm>
								}
							</div>
						</div>
					</div>
				</div>

			</section>
			<section className="container detail_page_blog_cat pt-0">
				<div className="title-section text-center">

					<div className="title-section text-center">
						{/* <h1></h1> */}
						<div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
							{/* <!-- Section heading --> */}
							<div className="rlr-section__title">
								<h2 className="rlr-section__title--main ">Blog by category</h2>

							</div>

						</div>
					</div>
				</div>
				<div className="widget category-widget">

					<ul className="category-list list_posts_tag list_posts_tag_d">
						{
							catList?.map((item, index) => {
								return (
									<Link target='_blank' to={openCat(item)}>
										{item?.title}
									</Link>
								)
							})
						}

					</ul>
				</div>
			</section>
		</>
	)
}
