import React, { useEffect } from 'react'
import bgImage from '../../assets/images/download_banner.jpg'
import { Link } from 'react-router-dom'
export default function Pricing() {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section class="page-title-big-typography pb-0 cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Join the Praarthana application</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Pricing</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="position-relative pt-0 z-index-2">
                <div className="container">
                    <div className="row justify-content-center mb-3">
                        <div className="col-md-7 text-center mt-4"
                            data-anime='{ "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div
                                className="bg-base-color d-inline-block mb-15px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                Subscription Plan</div>
                            <h3 className="fw-700 text-dark-gray w-85 lg-w-85 mx-auto ls-minus-1px">Choose the plan that's right for
                                you</h3>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center pricing-table-style-07">
                        <div className="col-lg-6 col-md-12 md-mb-30px">

                            <ul className="nav nav-tabs justify-content-center border-0 text-left"
                                data-anime='{ "el": "childs", "translateY": [-30, 0], "perspective": [1200,1200], "scale": [1.1, 1], "rotateX": [50, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <li className="nav-item mb-30px p-0">
                                    <a data-bs-toggle="tab" href="#tab_four1"
                                        className="nav-link active box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                        <div className="flex-column flex-sm-row d-flex align-items-center">
                                            <div
                                                className="col-auto align-items-center d-flex me-auto w-100px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                <div className='gold'>
                                                    <img src="https://praarthana.s3.ap-south-1.amazonaws.com/banner/1700813470537.webp" />
                                                </div>

                                            </div>
                                            {/* <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 20%</div> */}
                                            <div className="col ms-auto text-end mx-auto">
                                                <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">From ₹101</h5>
                                                <span className="d-inline-block align-middle ms-10px opacity-7">For 90 Days</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item mb-30px p-0">
                                    <a data-bs-toggle="tab" href="#tab_four2"
                                        className="nav-link box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                        <div className="flex-column flex-sm-row d-flex align-items-center">
                                            <div
                                                className="col-auto align-items-center d-flex me-auto w-100px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                <div className='gold'>
                                                    <img src="https://praarthana.s3.ap-south-1.amazonaws.com/banner/1700813530373.webp" />
                                                </div>

                                            </div>
                                            {/* <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 30%</div> */}
                                            <div className="col ms-auto text-end mx-auto">
                                                <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">From ₹251</h5>
                                                <span className="d-inline-block align-middle ms-10px opacity-7">For 365 Days </span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                {/* <li className="nav-item p-0">
                                    <a data-bs-toggle="tab" href="#tab_four3"
                                        className="nav-link box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                        <div className="flex-column flex-sm-row d-flex align-items-center">
                                            <div
                                                className="col-auto align-items-center d-flex me-auto w-150px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                <div
                                                    className="icon w-30px h-30px d-flex flex-shrink-0 align-items-center justify-content-center fs-11 border border-2 border-radius-100 me-10px">
                                                    <i className="fa-solid fa-check"></i>
                                                </div>
                                                <div className="fs-20 lg-fs-18 text-dark-gray fw-700 me-15px xs-me-0">Premium</div>
                                            </div>
                                            <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 35%</div>
                                            <div className="col ms-auto text-end mx-auto">
                                                <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">$28</h5>
                                                <span className="d-inline-block align-middle ms-10px opacity-7">Per month</span>
                                            </div>
                                        </div>
                                    </a>
                                </li> */}
                            </ul>

                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="tab-content"
                                data-anime='{ "el": "childs", "translateY": [-30, 0], "perspective": [1200,1200], "scale": [1.05, 1], "rotateX": [5, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>

                                <div className="tab-pane position-relative fade in active show box-shadow-quadruple-large border-radius-8px pt-40px"
                                    id="tab_four1">
                                    <span
                                        className="text-dark-gray fw-700 mb-15px fs-20 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">Praarthana Gold</span>
                                    <div className="pricing-body overflow-hidden">
                                        <ul className="p-0 list-style-02 text-start">
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>
                                                Collection of all major Aartis
                                            </li>
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Collection of chalisas of all the gods
                                            </li>
                                            <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>A unique collection of bhajans full of devotion.
                                            </li>
                                        </ul>
                                        <div
                                            className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                            <Link to="/download"
                                                className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                this plan</Link>
                                            <span className="fs-15 ms-15px d-inline-block">For 90 Days</span>
                                        </div>
                                    </div>
                                    <span
                                        className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Gold</span>
                                </div>


                                <div className="tab-pane position-relative fade in box-shadow-quadruple-large border-radius-8px pt-40px"
                                    id="tab_four2">
                                    <span
                                        className="text-dark-gray fw-700 mb-15px fs-20 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">Praarthana Platinum</span>
                                    <div className="pricing-body overflow-hidden">
                                        <ul className="p-0 list-style-02 text-start">
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i> Collection of all major Aartis
                                            </li>
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Collection of chalisas of all the gods
                                            </li>
                                            <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>A unique collection of bhajans full of devotion.
                                            </li>
                                        </ul>
                                        <div
                                            className="d-block bg-gradient-very-light-gray pt-25px pb-25px ps-45px pe-45px sm-ps-25px sm-pe-25px border-radius-8px">
                                            <Link to="/download"
                                                className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                this plan</Link>
                                            <span className="fs-15 ms-15px d-inline-block">For 365 Days</span>
                                        </div>
                                    </div>
                                    <span
                                        className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Platinum</span>
                                </div>


                                <div className="tab-pane position-relative fade in box-shadow-quadruple-large border-radius-8px pt-40px"
                                    id="tab_four3">
                                    <span
                                        className="text-dark-gray fw-700 mb-15px fs-24 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">The
                                        most premium plan</span>
                                    <div className="pricing-body overflow-hidden">
                                        <ul className="p-0 list-style-02 text-start">
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Find places
                                                near hotels and camp
                                            </li>
                                            <li
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Track record
                                                and reward programs
                                            </li>
                                            <li
                                                className="pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Shows
                                                transportation option
                                            </li>
                                        </ul>
                                        <div
                                            className="d-block bg-gradient-very-light-gray pt-25px pb-25px ps-45px pe-45px sm-ps-25px sm-pe-25px border-radius-8px">
                                            <a href="#"
                                                className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                this plan</a>
                                            <span className="fs-15 ms-15px d-inline-block">Offer save 35%*</span>
                                        </div>
                                    </div>
                                    <span
                                        className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Premium</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center align-items-center pt-5"
                        data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                        <div className="col-12 text-center align-items-center">
                            <div
                                className="bg-white border border-1 border-color-extra-medium-gray box-shadow-extra-large fw-800 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 me-10px xs-m-10px d-inline-block align-middle">
                                Contact</div>
                            <div className="text-dark-gray d-block d-sm-inline-block align-middle fs-18 fw-600 ls-minus-05px">
                                Looking for a corporate solution? <a href="#"
                                    className="fw-800 text-dark-gray text-decoration-line-bottom">Contact us</a></div>
                        </div>
                    </div> */}
                </div>
            </section >
        </>
    )
}
