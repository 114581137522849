import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../assets/images/download_banner.jpg'
import bgImage1 from '../../assets/images/demo-application-how-it-works-bg-01.jpg'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { Swiper, SwiperSlide } from 'swiper/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { EffectCards, Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';
import Tsparticles from '../component/Tsparticles';

export default function About() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <>
            <Helmet>
                <title>About Us | Praarthana App</title>
                <meta name="description" content="Download the Praarthana app and embark on a spiritual journey. Access a rich library of prayers and hymns, receive daily inspiration, and connect with a community of like-minded individuals." />
                <meta name="keywords" content="Praarthana, prayer app, spiritual journey, prayers, hymns, daily inspiration, spiritual growth" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Praarthana App - Your Personal Prayer Companion" />
                <meta property="og:description" content="Experience the spiritual journey with Praarthana. Access a wide range of prayers and hymns, receive daily inspiration, and connect with a supportive community." />
                <meta property="og:url" content="https://www.praarthana.app" />
                <meta property="og:image" content="https://www.praarthana.app/og.png" />
                <meta property="og:location" content="India" />
            </Helmet>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-8 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Join the Praarthana App</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">About Us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-0 cover-background cover_background_home home-banner  ipad-top-space-margin md-h-auto position-relative md-pb-70px"
            >
                <div id="particles-style-01" className="h-100 position-absolute left-0px top-0 w-100" data-particle="true"
                >
                    <Tsparticles />
                </div>
                <div className="container h-100 position-relative z-index-9">
                    <div className="row align-items-center h-100 justify-content-center ">
                        <div className="col-lg-6 col-md-10 text-center position-relative md-mb-50px left-section"
                            data-anime='{ "el": "childs", "opacity": [0, 1], "translateY": [80, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <div className="d-inline-block">
                                <div className="text-end w-90 lg-w-80 ms-auto animation-float">
                                    <img src={require("../../assets/image/prarthna_home_img01.png")} alt="" />
                                </div>
                            </div>
                            <div
                                className="w-40 position-absolute left-minus-40px lg-left-minus-30px xs-left-15px xs-w-50 bottom-minus-50px mb-30 xs-mb-15">
                                <img src={require("../../assets/image/prarthna_home_img02.png")} className="border-radius-18px box-shadow-extra-large"
                                    alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 ps-3 md-ps-15px col-lg-6 col-md-9 position-relative text-center text-lg-start"
                            data-anime='{ "el": "childs", "translateY": [50, 0], "perspective": [1200,1200], "scale": [1.1, 1], "rotateY": [50, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div className="fs-70 xl-fs-40 lg-fs-50 lh-80 mb-35px text-dark-gray ls-minus-3px">
                                <span className="d-inline-block sub-heading mb-3" >Best mobile App for</span>
                                <span className="fw-800 d-inline-block letter_spacing">Hindu Devotionals</span>
                            </div>
                            <span className="fs-16 w-90 xs-w-100 d-block lh-28 mb-35px mx-auto mx-lg-0">Discover the ultimate devotional companion with the Praarthana app. Immerse yourself in a rich collection of prayers, bhajans, and spiritual content tailored for devotees of Hinduism. <br /><br /><span style={{ fontWeight: 'bold' }} className='mt-3'>Stay connected with your faith, access daily prayers, and find peace and tranquility with our user-friendly mobile app.</span></span>
                            <div className="row pe-20px md-ps-25px sm-px-0 md-border-end-0">
                                <a href="https://apps.apple.com/in/app/praarthana/id1638860087" target='_blank' className="col-6">
                                    <img src={require("../../assets/image/app-store-white.png")}
                                        className="box-shadow-medium-bottom box-shadow-quadruple-large-hover border-radius-4px"
                                        alt="" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.praarthana" target='_blank' className="col-6">
                                    <img src={require("../../assets/image/play-store-white.png")}
                                        className="box-shadow-medium-bottom box-shadow-quadruple-large-hover border-radius-4px"
                                        alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="marquees-text fw-800 fs-250 md-fs-200 ls-minus-10px text-dark-gray text-nowrap position-absolute bottom-130px opacity-1 text-center">
                    praarthana application praarthana application</div>

            </section>


        </>
    )
}
