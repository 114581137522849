
import React, { useEffect, useState } from 'react';
import './style.css'
// import './mite-assets.min.css'
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from 'react-toastify';
import { get, post } from '../../helpers/helper_api';
import useMasonry from "./use-masonry";
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Loader from '../component/Loader';
import { Helmet } from 'react-helmet-async';
import bgImage from "../../assets/images/download_banner.jpg";
import moment from "moment";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

export default function BlogList(props) {
    const params = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataPro, setDataPro] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [headerData, setHeaderData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPagePro, setCurrentPagePro] = useState(0);
    const [totalPagePro, setTotalPagePro] = useState(0);
    const [loadingPro, setLoadingPro] = useState(false);

    const { categories } = useMasonry(
        data,
        ".portfolio-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button button"
    );

    useEffect(() => {
        console.log("category", params);
        getData(0, params?.cat);
        setCategoryId(params?.cat);
    }, [params])

    useEffect(() => {
        getCats();
    }, []);

    const getCats = () => {
        get('blog/category/web').then(async (json) => {
            // console.log('bank detail',json)
            // console.log('propertyId detail', json?.data);
            if (json?.statusCode == 200) {
                setAllCategories(json?.data);
            }
            //   setLoader(false);
        }).catch((err) => {
            console.log(err)
            //   setLoader(false);
        });
        get('tags/web').then(async (json) => {
            // console.log('bank detail',json)
            // console.log('propertyId detail', json?.data);
            if (json?.statusCode == 200) {
                setAllTags(json?.data);
            }
            //   setLoader(false);
        }).catch((err) => {
            console.log(err)
            //   setLoader(false);
        })
    }

    const getData = async (cp, categoryId) => {
        post("blog/list_by_cat", { categoryId, currentPage: cp })
            .then(res => {
                if (res?.statusCode == 200) {
                    let temp = cp == 0 ? [] : Object.assign([], data);
                    res?.data?.map((item, index) => {
                        // console.log(index%8);
                        if (index > 0 && index % 8 == 0) {
                            temp.push({ type: "tags" });
                            temp.push(item)
                        } else {
                            temp.push(item)
                        }
                    });
                    // console.log('data to set', temp.length);
                    setData(temp);
                    setTotalPage(res?.totalPage);
                    setCurrentPage(res?.currentPage + 1);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("We are having a problem to load the blog, Please Try again later!")
                }
            })
            .catch(err => {
                setLoading(false);
                console.log('error while getting blogs', err);
                toast.error("We are having a problem to load the blog, Please Try again later!")
            });
    }

    useEffect(() => {
        let added = document.getElementById("2368669");
        if (added)
            added.remove();

        // setLoading(false);
        setTimeout(() => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "2368669";
            script.async = true;
            script.function = ((d, sc, u) => {
                var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
                // console.log('getting element', s, p)
                s.type = 'text/javascript';
                s.async = true;
                s.src = u + '?v=' + (+new Date());
                p.parentNode.insertBefore(s, p);
            })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
            document.body.appendChild(script);

        }, 500);
    }, []);

    useEffect(() => {
        let added = document.getElementById("2312218");
        if (added)
            added.remove();

        // setLoading(false);
        setTimeout(() => {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.id = "2312218";
            script.async = true;
            script.function = ((d, sc, u) => {
                var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
                // console.log('getting element', s, p)
                s.type = 'text/javascript';
                s.async = true;
                s.src = u + '?v=' + (+new Date());
                p.parentNode.insertBefore(s, p);
            })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
            document.body.appendChild(script);

        }, 500);
    }, []);

    const compressId = (id) => {
        const temp = id.slice(id.length - 4, id.length);
        // console.log('compressing id', temp);
        return temp;
    }

    const parseName = (str) => {
        if (str) {
            let temp = str.split(",").join("");
            return temp.split(" ").join("-").toLowerCase();
        } else
            return "";
    }

    const openTag = (item) => {
        return `/blogs/tag/${parseName(item?.title)}`
    }

    const openBlog = (item) => {
        return "/blog/" + item?.link;
    }

    const openCat = (item) => {
        return `/blogs/category/${parseName(item?.title)}`
    }

    return (
        <>
            {
                loading && <Loader />
            }
            <Helmet>
                <title>Blogs | Praarthana</title>
                <meta name="keyword" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
                <meta name="description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
                <meta name="dc.language" content="English" />
                <meta name="dc.source" content="http://www.woowdestinations.com" />
                <meta name="dc.title" content="Woow Destinations" />
                <meta name="dc.keywords" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
                <meta name="dc.description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
                <meta name="Author" content="Woow Destinations" />
                <meta name="robots" content="index, follow" />
                <meta property="og:locale" content="en-in" />
                <meta property="og:type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="canonical" href="http://www.woowdestinations.com" />
            </Helmet>
            <section class="page-title-big-typography pb-0 cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>

                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Join the Praarthana application</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Blog</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pt-0 pb-0'>
                <div className="container h-100 position-relative z-index-9">
                    <section className="top-home-section top_home_section">
                        <div className="container">
                            <div className="top-home-box top-home-box-common-overlay">
                                <div className="row">
                                    {data?.length > 0 &&
                                        <div className="col-lg-6 col-md-12">
                                            <div className="news-post image-post image_post_blogdetail image_post_blogdetail_one">
                                                <img src={data[0]?.image} alt={data[0]?.title} />
                                                <div className="hover-post">
                                                    <h2><Link target='_blank' to={openBlog(data[0])}>{data[0]?.title?.length > 95 ? data[0]?.title?.substring(0, 95) + "..." : data[0]?.title}</Link></h2>
                                                    <ul className="post-tags">
                                                        <li><Link target='_blank' to={openCat(data[0]?.category[0])}>{data[0]?.category[0]?.title}</Link></li>
                                                        <li>{moment(data[0]?.updatedAt).format("MMM DD, YYYY")}</li>
                                                    </ul>
                                                </div>
                                                <div className='overlay'></div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-6 col-md-12">
                                        <div className="row">
                                            {data?.length > 1 &&
                                                <div className="col-md-6">
                                                    <div className="news-post standard-post left-align standard_post_left_align">
                                                        <div className="image-holder" >
                                                            <Link href="#"><img src={data[1]?.image} alt={data?.title} /></Link>
                                                            <div className='overlay'></div>
                                                        </div>
                                                        <h2><Link target='_blank' to={openBlog(data[1])}>{data[1]?.title?.length > 40 ? data[1]?.title?.substring(0, 40) + "..." : data[1]?.title}</Link></h2>
                                                        <ul className="post-tags">
                                                            <li><Link target='_blank' to={openCat(data[1]?.category[0])}>{data[1]?.category[0]?.title}</Link></li>
                                                            <li>{moment(data[1]?.updatedAt).format("MMM DD, YYYY")}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                            {data?.length > 2 &&
                                                <div className="col-md-6">
                                                    <div className="news-post standard-post left-align standard_post_left_align">
                                                        <div className="image-holder" >
                                                            <Link ><img src={data[2]?.image} alt={data?.title} /></Link>
                                                            <div className='overlay'></div>
                                                        </div>
                                                        <h2><Link target='_blank' to={openBlog(data[2])}>{data[2]?.title?.length > 40 ? data[2]?.title?.substring(0, 40) + "..." : data[2]?.title}</Link></h2>
                                                        <ul className="post-tags">
                                                            <li><Link target='_blank' to={openCat(data[2]?.category[0])}>{data[2]?.category[0]?.title}</Link></li>
                                                            <li>{moment(data[2]?.updatedAt).format("MMM DD, YYYY")}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {data?.length > 3 &&
                                            <div className="news-post image-post image_post_blogdetail image_post_blogdetail_two">
                                                <img src={data[3]?.image} alt={data[3]?.title} />
                                                <div className="hover-post">
                                                    <h2><Link target='_blank' to={openBlog(data[3])}>{data[3]?.title?.length > 80 ? data[3]?.title?.substring(0, 80) + "..." : data[3]?.title}</Link></h2>
                                                    <ul className="post-tags">
                                                        <li><Link target='_blank' to={openCat(data[3]?.category[0])}>{data[3]?.category[0]?.title}</Link></li>
                                                        <li>{moment(data[3]?.updatedAt).format("MMM DD, YYYY")}</li>
                                                    </ul>
                                                </div>
                                                <div className='overlay'></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section >


            <section className="fresh-section2 p-0">
                <div className="container">
                    <div className="title-section text-center">
                        <div className="title-section">
                            {/* <h1></h1> */}
                            <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
                                {/* <!-- Section heading --> */}
                                <div className="rlr-section__title">
                                    <h2 className="rlr-section__title--main ">Blog by category</h2>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="widget category-widget">
                        {allCategories?.length ?
                            <ul className="category-list list_posts_tag list_posts_tag_d">
                                {
                                    allCategories?.map((item, index) => {
                                        return (
                                            <Link target='_blank' to={openCat(item)}>
                                                {item?.title}
                                            </Link>
                                        )
                                    })
                                }
                            </ul>
                            : null}
                    </div>
                </div>
            </section>
            <section className="blog-section mt-0 pb-0 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-box ">
                                <div className="title-section  title_section_blog_list">
                                    {data?.length > 3 ?
                                        <div className="title-section text-center">
                                            <div className="title-section">
                                                <div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
                                                    <div className="rlr-section__title">
                                                        <h2 className="rlr-section__title--main ">Latest Posts</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-2 portfolio-list mb-n30">
                                    {data?.length > 0 &&
                                        data.map((item, index) => {
                                            if (index > 3) {
                                                if (item?.type == 'tags') {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            group={`any`}
                                                            className={`col masonry-grid mb-50`}>
                                                            <div className='item'>
                                                                <div className='news-post article-post drive'>
                                                                    <div className='tags_section image-holder'>
                                                                        <div className="widget list-widget">
                                                                            <h2>Popular mentions</h2>
                                                                            <ul className="list-posts list_posts_tag">
                                                                                {
                                                                                    allTags?.map((tag, index) => {
                                                                                        return (
                                                                                            <Link target='_blank' className="text-link" to={openTag(tag)}>{tag?.title}</Link>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div className="widget category-widget">
                                                                            <h2>Categories</h2>
                                                                            <ul className="category-list list_posts_tag">
                                                                                {
                                                                                    allCategories?.map((item, index) => {
                                                                                        return (
                                                                                            <Link target='_blank' to={openCat(item)}>
                                                                                                {item?.title}
                                                                                            </Link>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            group={`any`}
                                                            className={`col masonry-grid mb-50 `}
                                                        >
                                                            <div className="item ">
                                                                <div className="news-post article-post">
                                                                    <div className="image-holder">
                                                                        <img src={item?.image} alt={item?.title} />
                                                                    </div>
                                                                    <h2 className='ganesa'><Link target='_blank' to={openBlog(item)}>{item?.title}</Link></h2>
                                                                    <ul className="post-tags">
                                                                        <li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
                                                                        {item?.tags?.map((tag) => (
                                                                            <Link target='_blank' className="text-link text_link_" to={openTag(tag)}>{tag?.title}</Link>
                                                                        ))}
                                                                    </ul>
                                                                    <Link target='_blank' className="text-link" to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                        )
                                    }
                                </div>
                                {currentPage < (totalPage / 12).toFixed(0) &&
                                    <div className="center-button">
                                        <a className="button-one" onClick={() => getData(currentPage + 1, categoryId)}>Load More</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}