import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Footer from '../pages/component/Footer';
import BlogList from '../pages/Blog';
import AllBlog from "../pages/Blog/BlogList";
import AllBlogTag from "../pages/Blog/BlogListTag";
import Header from '../pages/component/Header';
import BlogDetails from '../pages/Blog/BlogDetails';
import Download from "../pages/Download";
import Checkout from "../pages/Checkout";
import NotFound from '../pages/NotFound';
import Success from '../pages/component/Success';
import Failed from '../pages/component/Failed';
import Privacy from '../pages/Other/Privacy';
import RefundsandcancellationPolicy from '../pages/Other/RefundsandcancellationPolicy';
import Pricing from '../pages/Other/Pricing';
import Features from '../pages/Other/Features';
import Terms from '../pages/Other/Terms';
import Contact from '../pages/Other/Contact';
import About from '../pages/Other/About';

export default function Router() {

    const location = useLocation();
    const [headFoot, setHeadFoot] = useState(true);

    useEffect(() => {
        // Hide Header and Footer for the checkout route
        if (location.pathname.startsWith('/checkout')) {
            setHeadFoot(false);
        } else {
            setHeadFoot(true);
        }
    }, [location.pathname]);

    return (
        // <BrowserRouter>
        <>
            {headFoot ?
                <Header />
                : null}
            <Routes>
                <Route index element={<Home />} />
                <Route path='/download' element={<Download />} />
                <Route path='/checkout/:userId/:planId/:couponId' element={<Checkout />} />
                <Route path='/blog' element={<BlogList />} />
                <Route path='/blogs' element={<AllBlog />} />
                <Route path='/blogs/category/:cat' element={<AllBlog />} />
                <Route path='/blogs/tag/:tag' element={<AllBlogTag />} />
                <Route path='/blog/:url' element={<BlogDetails />} />
                <Route path='/blog-details' element={<BlogDetails />} />
                <Route path='/payment/success' element={<Success />} />
                <Route path='/payment/failed' element={<Failed />} />
                <Route path='/features' element={<Features />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/refunds-and-cancellation-policy' element={<RefundsandcancellationPolicy />} />
                <Route path='/*' element={<NotFound />} />
                {/* <Route path="*" element={<NoPage />} /> */}
            </Routes>
            {headFoot ?
                <Footer />
                : null}
            {/* </BrowserRouter> */}
        </>
    )
}