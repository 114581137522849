import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Failed() {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/");
        }, 3000);
    }, []);

    return (
        <div>
            <section class="banner-section">
                <div class="banner-content">
                    <div className='success_section'>
                        <h4>Failed</h4>
                    </div>
                </div>
            </section>
            <div className='top'></div>
        </div>
    )
}
