import React from 'react'

export default function Loader() {
  return (
    <div className='loader-section'>
      <div className="loader">
        <img src={require('../../assets/image/praarthana_red.png')} alt="loading" />
        <span></span>
      </div>
    </div>
  )
}
