
import React, { useEffect } from 'react'
import bgImage from '../../assets/images/demo-application-page-title-bg.jpg'
import bgImage1 from '../../assets/images/demo-application-page-title-bg.jpg'
import { Link } from 'react-router-dom'
export default function Features() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Praarthana App</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Features</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section class="position-relative pt-0">
                <div class="container position-relative z-index-1">
                    <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center mb-6 sm-mb-9" data-anime='{ "el": "childs", "perspective": [1200,1200], "scale": [1.05, 1], "rotateY": [30, 0], "translateX": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>

                        <div class="col icon-with-text-style-04 transition-inner-all mb-30px">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Guaranteed safety</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col icon-with-text-style-04 transition-inner-all mb-30px">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Fast performance</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                                <span class="position-absolute top-25px right-25px bg-dark-gray border-radius-18px text-white fs-11 fw-700 text-uppercase ps-15px pe-15px lh-26">New</span>
                            </div>
                        </div>

                        <div class="col icon-with-text-style-04 transition-inner-all mb-30px">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Awesome design</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col icon-with-text-style-04 transition-inner-all md-mb-30px">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Tracking services</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col icon-with-text-style-04 transition-inner-all sm-mb-30px">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Fully secured</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col icon-with-text-style-04 transition-inner-all">
                            <div class="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                <div class="feature-box-icon mb-30px">
                                    <img src="https://via.placeholder.com/60x60" class="w-60px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <span class="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">24/7 online support</span>
                                    <p>We believe that what we create today, it will transform to brand growth in future.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 text-center" data-anime='{ "translateX": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 250, "easing": "easeOutQuad" }'>
                            <div
                                className="bg-white border border-1 border-color-extra-medium-gray box-shadow-extra-large fw-800 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 me-10px sm-m-10px d-inline-block align-middle">
                                hurray</div>
                            <div className="text-dark-gray d-block d-sm-inline-block align-middle fs-18 fw-600 ls-minus-05px">
                                Subscribe <Link to="/pricing"
                                    className="fw-800 text-decoration-line-bottom text-dark-gray">Praarthana App</Link> and get
                                a
                                special discount.</div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid position-absolute top-50 translate-middle-y z-index-minus-1 d-none d-md-block overflow-hidden">
                    <div class="row position-relative">
                        <div class="col swiper swiper-width-auto text-center pb-30px feather-shadow" data-slider-options='{ "slidesPerView": "auto", "spaceBetween":50, "speed": 10000, "loop": true, "pagination": { "el": ".slider-four-slide-pagination-2", "clickable": false }, "allowTouchMove": false, "autoplay": { "delay":0, "disableOnInteraction": false }, "navigation": { "nextEl": ".slider-four-slide-next-2", "prevEl": ".slider-four-slide-prev-2" }, "keyboard": { "enabled": true, "onlyInViewport": true }, "effect": "slide" }'>
                            <div class="swiper-wrapper marquee-slide">

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">traveling</div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">adventure</div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">destinations</div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">traveling</div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">adventure</div>
                                </div>

                                <div class="swiper-slide">
                                    <div class="fw-800 fs-200 opacity-1">destinations</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0 position-relative">
                <div class="container-fluid">
                    <div class="row">
                        <div class="p-0 overlap-section position-absolute top-100px left-0px text-end w-auto" data-bottom-top="transform: translateY(-100px)" data-top-bottom="transform: translateY(100px)">
                            <img src="images/demo-application-about-bg-left.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row align-items-center" data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                        <div class="col-12">
                            <div class="bg-linen p-9 md-p-7 border-radius-6px overflow-hidden position-relative">
                                <div class="position-absolute right-70px md-right-20px top-minus-20px w-250px sm-w-180px xs-w-150px opacity-1"><img src="https://via.placeholder.com/237x236" alt="" /></div>
                                <div class="bg-base-color d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">Basic information</div>
                                <h3 class="fw-700 text-dark-gray ls-minus-1px">Frequently asked questions</h3>
                                <div class="accordion accordion-style-02" id="accordion-style-02" data-active-icon="icon-feather-minus" data-inactive-icon="icon-feather-plus">

                                    <div class="accordion-item active-accordion">
                                        <div class="accordion-header border-bottom border-color-transparent-dark-very-light">
                                            <a href="#" data-bs-toggle="collapse" data-bs-target="#accordion-style-02-01" aria-expanded="true" data-bs-parent="#accordion-style-02">
                                                <div class="accordion-title mb-0 position-relative text-dark-gray pe-30px">
                                                    <i class="feather icon-feather-minus fs-20"></i><span class="fs-17 fw-600">Find network providers in your area</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="accordion-style-02-01" class="accordion-collapse collapse show" data-bs-parent="#accordion-style-02">
                                            <div class="accordion-body last-paragraph-no-margin border-bottom border-color-transparent-dark-very-light">
                                                <p class="w-90 sm-w-95 xs-w-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exercitation.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <div class="accordion-header border-bottom border-color-transparent-dark-very-light">
                                            <a href="#" data-bs-toggle="collapse" data-bs-target="#accordion-style-02-02" aria-expanded="false" data-bs-parent="#accordion-style-02">
                                                <div class="accordion-title mb-0 position-relative text-dark-gray pe-30px">
                                                    <i class="feather icon-feather-plus fs-20"></i><span class="fs-17 fw-600">See your claims, check on coverage and more</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="accordion-style-02-02" class="accordion-collapse collapse" data-bs-parent="#accordion-style-02">
                                            <div class="accordion-body last-paragraph-no-margin border-bottom border-color-transparent-dark-very-light">
                                                <p class="w-90 sm-w-95 xs-w-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exercitation.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <div class="accordion-header border-bottom border-color-transparent">
                                            <a href="#" data-bs-toggle="collapse" data-bs-target="#accordion-style-02-03" aria-expanded="false" data-bs-parent="#accordion-style-02">
                                                <div class="accordion-title mb-0 position-relative text-dark-gray pe-30px">
                                                    <i class="feather icon-feather-plus fs-20"></i><span class="fs-17 fw-600">Easy access more mental health providers</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div id="accordion-style-02-03" class="accordion-collapse collapse" data-bs-parent="#accordion-style-02">
                                            <div class="accordion-body last-paragraph-no-margin border-bottom border-color-transparent">
                                                <p class="w-90 sm-w-95 xs-w-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud exercitation.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-6">

                        <div class="col-auto icon-with-text-style-08 sm-mb-15px xs-mb-15px" data-anime='{ "translateX": [-50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div class="feature-box feature-box-left-icon-middle xs-lh-28">
                                <div class="feature-box-icon me-10px">
                                    <i class="bi bi-envelope icon-extra-medium text-base-color"></i>
                                </div>
                                <div class="feature-box-content">
                                    <span class="alt-font fs-18 xs-fs-17 fw-600 text-dark-gray">Looking for help? <a href="mailto:hi@praarthana.app" class="text-decoration-line-bottom text-dark-gray">hi@praarthana.app</a></span>
                                </div>
                            </div>
                        </div>


                        <div class="col-auto icon-with-text-style-08" data-anime='{ "translateX": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div class="feature-box feature-box-left-icon-middle xs-lh-28">
                                <div class="feature-box-icon me-10px">
                                    <i class="icon feather icon-feather-facebook icon-extra-medium text-base-color"></i>
                                </div>
                                <div class="feature-box-content">
                                    <span class="alt-font fs-18 xs-fs-17 fw-600 text-dark-gray">Keep in Touch. <a href="https://www.facebook.com/HinduPraarthana/" target='_blank' class="text-decoration-line-bottom text-dark-gray">Like us on Facebook</a></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
