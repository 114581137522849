import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { EffectCards, Autoplay } from 'swiper/modules';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link, useLocation } from 'react-router-dom';
import { get } from '../../helpers/helper_api';
import bgImage from "../../assets/images/praarthana-banner.jpg";
import bgImage2 from "../../assets/images/demo-application-home-slider-bg.jpg";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Atropos from 'atropos';
import 'atropos/css'
import VerticalCounter from '../component/VerticalCounter';
import anime from 'animejs';
import CountUp from 'react-countup';
import Tsparticles from '../component/Tsparticles';
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';

export default function Home() {

    const [data, setData] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [tags, setTags] = useState([]);
    const [docWidth, setDocWidth] = useState(0);
    const [randomBlog, setRandomBlog] = useState(undefined);
    const swiperRef = useRef();
    const loaction = useLocation();


    useEffect(() => {
        window.addEventListener('resize', () => setDocWidth(window?.innerWidth));
    }, []);

    // useEffect(() => {

    //     const vendorsScript = document.createElement('script');
    //     vendorsScript.src = Vendors;
    //     vendorsScript.async = true;
    //     document.body.appendChild(vendorsScript);

    //     const mainScript = document.createElement('script');
    //     mainScript.src = Main;
    //     mainScript.async = true;
    //     document.body.appendChild(mainScript);


    //     const masonryScript = document.createElement('script');
    //     masonryScript.src = Jquery;
    //     masonryScript.async = true;
    //     document.body.appendChild(masonryScript);




    //     return () => {
    //         document.body.removeChild(vendorsScript);
    //         document.body.removeChild(mainScript);
    //         document.body.removeChild(masonryScript);
    //     };
    // }, [loaction?.pathname]); 

    console.log("width", docWidth);

    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState(null);

    useEffect(() => {
        if (previousLocation && location.pathname !== previousLocation.pathname) {
            window.location.reload();
        }
        setPreviousLocation(location);
    }, [location, previousLocation]);



    useEffect(() => {
        const elements = document.querySelectorAll('.my-atropos');
        const atroposInstances = [];

        elements.forEach(el => {
            const atroposInstance = Atropos({
                el: el,
            });
            atroposInstances.push(atroposInstance);
        });

        // Clean up the Atropos instances on component unmount or route change
        return () => {
            atroposInstances.forEach(instance => instance.destroy());
        };
    }, [location]); // Reinitialize on location (route) change

    useEffect(() => {
        window.scrollTo(0, 0);

        getData();
    }, []);

    const banner = useRef();
    const blog = useRef();

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        let tl = gsap.timeline()
        tl.to('.first_pillor', { 'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity: 1, duration: 1, delay: 0.5, autoAlpha: 1 })
        tl.to('.center-circle', { 'clip-path': 'circle(50% at 50% 50%)', opacity: 1, duration: 1, autoAlpha: 1 })
        tl.to('.logo', { 'clip-path': 'circle(50% at 50% 50%)', opacity: 1, duration: 1, autoAlpha: 1 })
        gsap.to('.slider-section', {
            opacity: 1,
            y: 0,
            duration: 3,
            autoAlpha: 1,
            delay: 2.5
        });
    })

    const getData = () => {
        get("screen/home/web")
            .then(res => {
                if (res?.statusCode == 200) {
                    setData(res?.data);
                    setBlogs(res?.data?.blogs);
                    setTags(res?.data?.tags);
                    setRandomBlog(res?.data?.randomBlog);
                }
            })
            .catch(err => {
                console.log("error", err);
            })
    }

    return (
        <ParallaxProvider>
            <div>
                <Helmet>
                    {/* <script type="text/javascript" src={require('../../assets/js/jquery.js')}></script> */}
                    {/* <script type="text/javascript" src={require('../../assets/js/vendors.min.js')}></script> */}
                    {/* <script type="text/javascript" src={require('../../assets/js/main.js')}></script> */}

                </Helmet>
                <section className="p-0 cover-background cover_background_home home-banner  ipad-top-space-margin md-h-auto position-relative md-pb-70px"
                    style={{ backgroundImage: docWidth < 575 ? '' : `url(${bgImage})` }}>
                    <div id="particles-style-01" className="h-100 position-absolute left-0px top-0 w-100" data-particle="true"
                    >
                        <Tsparticles />
                    </div>
                    <div className="container h-100 position-relative z-index-9">
                        <div className="row align-items-center h-100 justify-content-center ">
                            <div className="col-lg-6 col-md-10 text-center position-relative md-mb-50px left-section"
                                data-anime='{ "el": "childs", "opacity": [0, 1], "translateY": [80, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                                <div className="d-inline-block">
                                    <div className="text-end w-90 lg-w-80 ms-auto animation-float">
                                        <img src={require("../../assets/image/prarthna_home_img01.png")} alt="" />
                                    </div>
                                </div>
                                <div
                                    className="w-40 position-absolute left-minus-40px lg-left-minus-30px xs-left-15px xs-w-50 bottom-minus-50px mb-30 xs-mb-15">
                                    <img src={require("../../assets/image/prarthna_home_img02.png")} className="border-radius-18px box-shadow-extra-large"
                                        alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 ps-3 md-ps-15px col-lg-6 col-md-9 position-relative text-center text-lg-start"
                                data-anime='{ "el": "childs", "translateY": [50, 0], "perspective": [1200,1200], "scale": [1.1, 1], "rotateY": [50, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <div className="fs-70 xl-fs-40 lg-fs-50 lh-80 mb-35px text-dark-gray ls-minus-3px">
                                    <span className="d-inline-block sub-heading mb-3" >Download the praarthana app now!</span>
                                    <span className="fw-800 d-inline-block letter_spacing">Hindu Devotionals</span>
                                </div>
                                <span className="fs-16 w-90 xs-w-100 d-block lh-28 mb-35px mx-auto mx-lg-0">Discover the ultimate devotional companion with the Praarthana app. you need for your spiritual journey.</span>
                                <div className="row pe-20px md-ps-25px sm-px-0 md-border-end-0">
                                    <a href="https://apps.apple.com/in/app/praarthana/id1638860087" target='_blank' className="col-6">
                                        <img src={require("../../assets/image/app-store-white.png")}
                                            className="box-shadow-medium-bottom box-shadow-quadruple-large-hover border-radius-4px"
                                            alt="" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.praarthana" target='_blank' className="col-6">
                                        <img src={require("../../assets/image/play-store-white.png")}
                                            className="box-shadow-medium-bottom box-shadow-quadruple-large-hover border-radius-4px"
                                            alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="marquees-text fw-800 fs-250 md-fs-200 ls-minus-10px text-dark-gray text-nowrap position-absolute bottom-130px opacity-1 text-center">
                        praarthana application praarthana application</div>

                </section>

                {/* <section className="pb-0 half-section lg-pt-0">
                <div className="container">
                    <div className="row"
                        data-anime='{ "translateX": [0, 0], "opacity": [0,1], "duration": 600, "delay": 300, "staggervalue": 250, "easing": "easeOutQuad" }'>
                        <div className="col text-center">
                            <span className="fs-19 alt-font mb-35px d-inline-block text-dark-gray fw-600 ls-minus-05px">Join the
                                <span className="fw-800">10000+</span> people trusting praarthana application.</span>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 clients-style-06 justify-content-center"
                        data-anime='{ "el": "childs", "translateX": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 250, "easing": "easeOutQuad" }'>

                        <div className="col client-box text-center md-mb-35px">
                            <a href="#"><img src={require("../../assets/images/logo-walmart.svg" className="h-35px" alt="" /></a>
                        </div>

                        <div className="col client-box text-center md-mb-35px">
                            <a href="#"><img src={require("../../assets/images/logo-logitech.svg" className="h-35px" alt="" /></a>
                        </div>

                        <div className="col client-box text-center md-mb-35px">
                            <a href="#"><img src={require("../../assets/images/logo-monday.svg" className="h-35px" alt="" /></a>
                        </div>

                        <div className="col client-box text-center sm-mb-35px">
                            <a href="#"><img src={require("../../assets/images/logo-google.svg" className="h-35px" alt="" /></a>
                        </div>

                        <div className="col client-box text-center">
                            <a href="#"><img src={require("../../assets/images/logo-paypal.svg" className="h-35px" alt="" /></a>
                        </div>

                    </div>
                </div>
            </section> */}

                <section className="pb-0">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center mb-6 sm-mb-12 childs"
                        >

                            <div className="col icon-with-text-style-04 transition-inner-all md-mb-30px">
                                <div
                                    className="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                    <div className="feature-box-icon mb-25px">
                                        <img src={require("../../assets/image/calendar.png")} className="w-60px" alt="" />
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span className="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Hindi Calendar</span>
                                        <p>"The Praarthana app now features a detailed Hindi calendar. Plan your days with accurate information on dates and festivals."</p>

                                    </div>
                                    <span
                                        className="position-absolute top-25px right-25px bg-dark-gray border-radius-18px text-white fs-11 fw-700 text-uppercase ps-15px pe-15px lh-26">New</span>
                                </div>
                            </div>


                            <div className="col icon-with-text-style-04 transition-inner-all md-mb-30px">
                                <div
                                    className="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                    <div className="feature-box-icon mb-25px">
                                        <img src={require("../../assets/image/horos.png")} className="w-60px" alt="" />
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span className="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Horoscope Reading</span>
                                        <p>"Discover what the stars have in store for you with the Horoscope Reading feature in the Praarthana app. Get daily insights and guidance tailored to your zodiac sign."</p>

                                    </div>

                                </div>
                            </div>


                            <div className="col icon-with-text-style-04 transition-inner-all">
                                <div
                                    className="feature-box bg-white text-start justify-content-start h-100 border-radius-6px p-16 lg-p-13 box-shadow-quadruple-large box-shadow-quadruple-large-hover">
                                    <div className="feature-box-icon mb-25px">
                                        <img src={require("../../assets/image/wishes.png")} className="w-60px" alt="" />
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span className="d-inline-block text-dark-gray fw-700 fs-18 mb-5px">Wishes</span>
                                        <p>"Spread joy and warmth by sending heartfelt wishes through the Praarthana app. Express your love, gratitude, and good wishes in just a few words."

                                        </p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-center align-items-center"
                            data-anime='{ "translateX": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 250, "easing": "easeOutQuad" }'>
                            <div className="col-12 text-center align-items-center">
                                <div
                                    className="bg-white border border-1 border-color-extra-medium-gray box-shadow-extra-large fw-800 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 me-10px sm-m-10px d-inline-block align-middle">
                                    hurray</div>
                                <div className="text-dark-gray d-block d-sm-inline-block align-middle fs-18 fw-600 ls-minus-05px">
                                    Subscribe <Link to="/pricing"
                                        className="fw-800 text-decoration-line-bottom text-dark-gray">Praarthana App</Link> and get
                                    a
                                    special discount.</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="position-relative half-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="p-0 overlap-section position-absolute right-0px text-end w-auto xs-w-200px z-index-minus-1"
                                data-bottom-top="transform: translateY(-150px)" data-top-bottom="transform: translateY(150px)">
                                {/* <img src={require("../../assets/images/demo-application-home-bg-right.png" alt="" /> */}

                                <img src={require('../../assets/images/rightpath.png')}></img>
                            </div>
                        </div>
                    </div>
                    <div className="position-absolute left-0px bottom-minus-100px w-auto xs-w-180px z-index-minus-1"
                        data-bottom-top="transform: translateY(-100px)" data-top-bottom="transform: translateY(100px)">
                        {/* <img src={require("../../assets/images/demo-application-home-bg-left.png" alt="" /> */}

                        <img src={require('../../assets/images/leftpath (1).png')}></img>
                    </div>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-7 position-relative md-mb-20px"
                                data-anime='{ "effect": "slide", "color": "#ffffff", "direction":"lr", "easing": "easeOutQuad", "delay":50}'>
                                <figure>
                                    <div className="atropos my-atropos" data-atropos>
                                        <div className="atropos-scale">
                                            <div className="atropos-rotate">
                                                <div className="atropos-inner">
                                                    <img data-atropos-offset="5" src={require("../../assets/image/prarthna_home_img03.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <figcaption
                                        className="position-absolute bottom-90px xs-bottom-50px right-0px transform-3d xs-w-80 z-index-9">
                                        <img src={require("../../assets/image/prarthna_home_img04.png")} className="animation-float" alt="" />
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-lg-5 col-md-8 col-sm-10 text-center text-lg-start">
                                <div className="bg-base-color d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12"
                                    data-anime='{ "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                    How it works</div>
                                <h4 className="fw-700 mb-45px text-dark-gray ls-minus-1px"
                                    data-anime='{ "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                    Get notify for each hindu events, One day before
                                </h4>
                                <div className="row row-cols-1"
                                    data-anime='{ "el": "childs", "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>

                                    <div className="col-12 process-step-style-05 position-relative hover-box">
                                        <div className="process-step-item d-flex">
                                            <div className="process-step-icon-wrap position-relative">
                                                <div
                                                    className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-linen fs-15 fw-700 position-relative">
                                                    <span className="number position-relative z-index-1 text-dark-gray">01</span>
                                                    <div className="box-overlay bg-base-color rounded-circle"></div>
                                                </div>
                                                <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                            </div>
                                            <div className="process-content ps-35px last-paragraph-no-margin mb-30px">
                                                <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Events</span>
                                                <p className="w-100 lg-w-90 sm-w-100">Events like Ekadashi, Purnima, Pradosh Vart, Grahan, Amavashya and All festivals.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 process-step-style-05 position-relative hover-box">
                                        <div className="process-step-item d-flex">
                                            <div className="process-step-icon-wrap position-relative">
                                                <div
                                                    className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-linen fs-15 fw-700 fw-600 position-relative">
                                                    <span className="number position-relative z-index-1 text-dark-gray">02</span>
                                                    <div className="box-overlay bg-base-color rounded-circle"></div>
                                                </div>
                                                <span className="progress-step-separator bg-dark-gray opacity-1"></span>
                                            </div>
                                            <div className="process-content ps-35px last-paragraph-no-margin mb-30px">
                                                <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Open to choose</span>
                                                <p className="w-70 lg-w-90 sm-w-100">Praarthana App allow you to choose weather you want to get notify or not.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 process-step-style-05 position-relative hover-box">
                                        <div className="process-step-item d-flex">
                                            <div className="process-step-icon-wrap position-relative">
                                                <div
                                                    className="process-step-icon d-flex justify-content-center align-items-center mx-auto rounded-circle h-55px w-55px bg-linen fs-15 fw-700 fw-600 position-relative">
                                                    <span className="number position-relative z-index-1 text-dark-gray">03</span>
                                                    <div className="box-overlay bg-base-color rounded-circle"></div>
                                                </div>
                                            </div>
                                            <div className="process-content ps-35px last-paragraph-no-margin">
                                                <span className="d-block fw-700 text-dark-gray mb-5px fs-18">Get notify one day before</span>
                                                <p className="w-70 lg-w-90 sm-w-100">Receive the notification even before start the events and the same day also.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="p-0">
                    <div className="container">
                        <Parallax translateX={['20px', '-20px']}>
                            <div className="row m-0 align-items-center p-55px lg-p-35px md-p-50px xs-pt-40px xs-pb-40px xs-ps-15px xs-pe-15px justify-content-center bg-floral-white border-radius-6px"
                                data-bottom-top="transform: translate3d(30px, 0px, 0px);"
                                data-top-bottom="transform: translate3d(-30px, 0px, 0px);">
                                <div className="col-lg-6 text-dark-gray md-mb-50px sm-mb-40px">
                                    <div className="row align-items-center justify-content-center justify-content-lg-start">
                                        <div className="col-5 col-sm-4 md-mb-20px">
                                            <img src={require("../../assets/image/goldy.jpg")}
                                                className="rounded-circle w-100" alt="" /></div>
                                        <div className="col-lg-8 text-center text-lg-start">
                                            <div className="fs-19 lh-30 mb-3 w-90 md-w-75 sm-w-100 mx-auto mx-lg-0">"Prarthana Aap is my
                                                sanctuary, guiding me through spiritual paths with its
                                                comforting prayers and resources."</div>
                                            <div>
                                                <div className="text-dark-gray fw-700 fs-19 d-inline-block me-10px">Abhisek Goldy</div>
                                                <div className="review-star-icon position-relative d-inline-block top-0px fs-18">
                                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                            className="bi bi-star-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 col-lg-3 col-md-5 text-dark-gray border-start border-color-transparent-dark-very-light md-border-start-0 text-center text-lg-start ps-6 pe-6">
                                    <h3 className="mb-0 fw-800 ls-minus-1px lh-38">230+</h3>
                                    <span className="fs-17 fw-600">User reviews</span>
                                    <div className="review-star-icon fs-18">
                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                            className="bi bi-star-fill"></i><i className="bi bi-star-half"></i>
                                        <span className="fs-12 lh-16 d-block text-medium-gray">Rating on app stores</span>
                                    </div>
                                </div>
                                <div
                                    className="col-6 col-lg-3 col-md-5 text-dark-gray border-start border-color-transparent-dark-very-light text-center text-lg-start ps-6 pe-6">
                                    <h3 className="mb-0 fw-800 ls-minus-1px lh-38">50k+</h3>
                                    <span className="fs-17 fw-600">Installations</span>
                                    <div className="review-star-icon fs-18">
                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                            className="bi bi-star-fill"></i><i className="bi bi-star-half"></i>
                                        <span className="fs-12 lh-16 d-block text-medium-gray">Rating on app stores</span>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </section>

                <section className="stack-box py-0">
                    <div className="stack-box-contain">
                        <div className="stack-item stack-item-01 bg-white lg-pt-8 lg-pb-8 xs-pt-50px xs-pb-50px">
                            <div className="stack-item-wrapper">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-5 col-lg-5 col-md-6 sm-mb-40px text-center text-md-start"
                                            data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                                            <div
                                                className="d-inline-block mb-20px bg-base-color fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                                Categorized Wallpapers</div>
                                            <h4 className="fw-700 text-dark-gray ls-minus-1px">Devotional Wallpapers</h4>
                                            <p className="lg-w-85 md-w-95 sm-w-100">Aaccess beautiful dashing images and easily share your faith with friends and family. Experience the spiritual connection with daily prayers and inspiring content.
                                                <br /><br /><span style={{ fontWeight: 'bold' }}>Images that compatible to all mobiles devices and easily set the <strong className='bolg_red'>wallpaper</strong> on your home screen and lock screen.</span></p>
                                            {/* <img src={require("../../assets/image/prarthana-home-free-support-graphic.webp")} className="mt-10px" alt="" /> */}
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6 position-relative atropos my-atropos" data-atropos>
                                            <div className='right_section_dev'>
                                                <div className="atropos-scale section-moving">
                                                    <div className="atropos-rotate">
                                                        <div className="atropos-inner text-center">
                                                            <div data-atropos-offset="-5" className="position-absolute left-0px right-0px">
                                                                <img src={require("../../assets/image/gnol.png")} alt="" />
                                                            </div>
                                                            <img data-atropos-offset="5" className="position-relative z-index-9"
                                                                src={require("../../assets/image/prarthana_home_img09.png")} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stack-item stack-item-02 bg-linen lg-pt-8 lg-pb-8 xs-pt-50px xs-pb-50px">
                            <div className="stack-item-wrapper">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-5 col-lg-5 col-md-6 sm-mb-40px text-center text-md-start">
                                            <div
                                                className="d-inline-block mb-20px bg-base-color fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                                Stories</div>
                                            <h4 className="fw-700 text-dark-gray ls-minus-1px">Mythological Stories</h4>
                                            <p className="lg-w-85 md-w-95 sm-w-100">Explore the rich tapestry of Hindu mythology and spirituality with the Praarthana app's <strong className='bolg_red'>Stories</strong> section. Dive into captivating tales of gods, goddesses, and revered saints that inspire and uplift. <br /><br /><span style={{ fontWeight: 'bold' }}>Whether you seek wisdom, devotion, or a deeper understanding of your faith, our collection of stories offers something for everyone.</span>
                                            </p>
                                            {/* <img src={require("../../assets/image/prarthana-home-free-support-graphic.webp")} className="mt-10px" alt="" /> */}
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6 position-relative atropos transform-3d my-atropos" data-atropos>
                                            <div className='right_section_dev'>
                                                <div className="atropos-scale section-moving">
                                                    <div className="atropos-rotate">
                                                        <div className="atropos-inner text-center">
                                                            <div data-atropos-offset="-5" className="position-absolute left-0px right-0px">
                                                                <img src={require("../../assets/image/ganesha.png")} alt="" />
                                                            </div>
                                                            <img data-atropos-offset="5" className="position-relative z-index-9"
                                                                src={require("../../assets/image/prarthana_home_img10.png")} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="stack-item stack-item-03 bg-white lg-pt-8 xs-pt-50px xs-pb-20px">
                            <div className="stack-item-wrapper">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-5 col-lg-5 col-md-6 sm-mb-40px text-center text-md-start">
                                            <div
                                                className="d-inline-block mb-20px bg-base-color fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                                Data dashboard</div>
                                            <h4 className="fw-700 text-dark-gray ls-minus-1px">Pooja Kare</h4>
                                            <p className="lg-w-85 md-w-95 sm-w-100">Enhance your devotional practices with the <strong className='bolg_red'>"Pooja Kare"</strong> feature on the Praarthana app.
                                                <br />

                                                <strong className='bolg_red'>Donate Flowers:</strong> Offer vibrant flowers to your chosen deity.
                                                <br />

                                                <strong className='bolg_red'>Coconuts:</strong> Symbolize your devotion by donating coconuts.
                                                <br />
                                                <strong className='bolg_red'>Kalash:</strong> Present a sacred kalash as a mark of respect and purity.
                                                <br />
                                                <strong className='bolg_red'>Ring the Bell:</strong> Create a divine ambiance by ringing the virtual bell.
                                                <br />
                                                <strong className='bolg_red'>Lights:</strong> Light diyas to illuminate your spiritual path.
                                            </p>

                                            {/* <img src={require("../../assets/image/prarthana-home-free-support-graphic.webp")} className="mt-10px" alt="" /> */}
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6 position-relative atropos transform-3d my-atropos" data-atropos>
                                            <div className='right_section_dev'>
                                                <div className="atropos-scale section-moving">
                                                    <div className="atropos-rotate">
                                                        <div className="atropos-inner text-center">
                                                            <div data-atropos-offset="-5" className="position-absolute left-0px right-0px">
                                                                <img src={require("../../assets/image/grety.png")} alt="" />
                                                            </div>
                                                            <img data-atropos-offset="5" className="position-relative z-index-9"
                                                                src={require("../../assets/image/prarthana_home_img11.png")} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="pb-0 position-relative">
                    <div className="container-fluid">
                        <div className="row justify-content-center lg-mx-0">
                            <div className="col-xxl-10 col-xl-11 cover-background p-6 border-radius-10px"
                                style={{ backgroundImage: `url(${bgImage2})` }}
                            >
                                <div className="row align-items-center">
                                    <div className="col-xxl-3 col-xl-4 position-relative text-center text-xl-start"
                                        data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                        <div
                                            className="bg-white-transparent-very-light d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                            how it works</div>
                                        <h4 className="fw-600 text-white ls-minus-1px">What the spiritual community is saying.</h4>
                                        <div className="d-flex lg-mb-35px justify-content-center justify-content-xl-start left-right-slider-btn">

                                            <div
                                                onClick={() => swiperRef.current?.slidePrev()}
                                                className="slider-one-slide-prev-1 text-white swiper-button-prev slider-navigation-style-04 border border-2 border-color-transparent-white-light">
                                                <i className="feather icon-feather-arrow-left icon-small"></i>
                                            </div>
                                            <div
                                                onClick={() => swiperRef.current?.slideNext()}
                                                className="slider-one-slide-next-1 text-white swiper-button-next slider-navigation-style-04 border border-2 border-color-transparent-white-light">
                                                <i className="feather icon-feather-arrow-right icon-small"></i>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xxl-9 col-xl-8 ps-6 lg-ps-15px position-relative"
                                        data-anime='{ "translateY": [0, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                        <div className="outside-box-right-20 sm-outside-box-right-0">
                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={10}

                                                breakpoints={{
                                                    640: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 20,
                                                    },
                                                    768: {
                                                        slidesPerView: 2.4,
                                                        spaceBetween: 20,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3.6,
                                                        spaceBetween: 20,
                                                    },
                                                }}

                                                onBeforeInit={(swiper) => {
                                                    swiperRef.current = swiper;
                                                }}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                modules={[Autoplay]}
                                                loop={true}
                                                className="mySwiper testimonial_slider"
                                            >
                                                <SwiperSlide className='review-style-07'>
                                                    <div
                                                        className="d-flex justify-content-center h-100 flex-column border-radius-6px p-12 xs-p-8 bg-oxford-blue">
                                                        <div className="mb-20px">
                                                            <img src={require("../../assets/image/prarthana_home_img12.png")}
                                                                className="rounded-circle w-90px h-90px me-15px" alt="" />
                                                            <div className="d-inline-block align-middle">
                                                                <div className="text-white fw-600 fs-18">Kartik Vats</div>
                                                                <div className="lh-26 fs-15 fw-600">Google Review</div>
                                                            </div>
                                                        </div>
                                                        <p className="mb-15px">This is the very first time I have connected to such a devotional app whose features are very impressive.
                                                        </p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block me-auto">
                                                                <div className="text-white float-start me-5px fs-12 fw-500">5.0</div>
                                                                <div className="review-star-icon float-start">
                                                                    <i className="bi bi-star-fill fs-12"></i><i
                                                                        className="bi bi-star-fill fs-12"></i><i
                                                                            className="bi bi-star-fill fs-12"></i><i
                                                                                className="bi bi-star-fill fs-12"></i><i
                                                                                    className="bi bi-star-fill fs-12"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="d-inline-block fs-12 bg-medium-gray-transparent fw-600 text-uppercase border-radius-3px ps-15px pe-15px fs-12 lh-26 text-white">
                                                                20 Jan</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className='review-style-07'>
                                                    <div
                                                        className="d-flex justify-content-center h-100 flex-column border-radius-6px p-12 xs-p-8 bg-oxford-blue">
                                                        <div className="mb-20px">
                                                            <img src={require("../../assets/image/prarthana_home_img13.png")}
                                                                className="rounded-circle w-90px h-90px me-15px" alt="" />
                                                            <div className="d-inline-block align-middle">
                                                                <div className="text-white fw-600 fs-18">Kajal Saini</div>
                                                                <div className="lh-26 fs-15 fw-600">Google Review</div>
                                                            </div>
                                                        </div>
                                                        <p className="mb-15px">"Essential app for spiritual growth, offering prayers
                                                            and uplifting resources."</p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block me-auto">
                                                                <div className="text-white float-start me-5px fs-12 fw-500">4.5</div>
                                                                <div className="review-star-icon float-start">
                                                                    <i className="bi bi-star-fill fs-12"></i><i
                                                                        className="bi bi-star-fill fs-12"></i><i
                                                                            className="bi bi-star-fill fs-12"></i><i
                                                                                className="bi bi-star-fill fs-12"></i><i
                                                                                    className="bi bi-star-half fs-12"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="d-inline-block fs-12 bg-medium-gray-transparent fw-600 text-uppercase border-radius-3px ps-15px pe-15px fs-12 lh-26 text-white">
                                                                10 Jan</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className='review-style-07'>
                                                    <div
                                                        className="d-flex justify-content-center h-100 flex-column border-radius-6px p-12 xs-p-8 bg-oxford-blue">
                                                        <div className="mb-20px">
                                                            <img src={require("../../assets/image/prarthana_home_img14.png")}
                                                                className="rounded-circle w-90px h-90px me-15px" alt="" />
                                                            <div className="d-inline-block align-middle">
                                                                <div className="text-white fw-600 fs-18">Pooja Khatri</div>
                                                                <div className="lh-26 fs-15 fw-600">Google Review</div>
                                                            </div>
                                                        </div>
                                                        <p className="mb-15px">"Praarthana App enriches my faith with its diverse
                                                            prayer options."</p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block me-auto">
                                                                <div className="text-white float-start me-5px fs-12 fw-500">5.0</div>
                                                                <div className="review-star-icon float-start">
                                                                    <i className="bi bi-star-fill fs-12"></i><i
                                                                        className="bi bi-star-fill fs-12"></i><i
                                                                            className="bi bi-star-fill fs-12"></i><i
                                                                                className="bi bi-star-fill fs-12"></i><i
                                                                                    className="bi bi-star-half fs-12"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="d-inline-block fs-12 bg-medium-gray-transparent fw-600 text-uppercase border-radius-3px ps-15px pe-15px fs-12 lh-26 text-white">
                                                                01 Jan</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className='review-style-07'>
                                                    <div
                                                        className="d-flex justify-content-center h-100 flex-column border-radius-6px p-12 xs-p-8 bg-oxford-blue">
                                                        <div className="mb-20px">
                                                            <img src={require("../../assets/image/prarthana_home_img15.png")}
                                                                className="rounded-circle w-90px h-90px me-15px" alt="" />
                                                            <div className="d-inline-block align-middle">
                                                                <div className="text-white fw-600 fs-18">Akash Bhardwaj</div>
                                                                <div className="lh-26 fs-15 fw-600">Google Review</div>
                                                            </div>
                                                        </div>
                                                        <p className="mb-15px">"Praarthana App guides my spiritual journey
                                                            effortlessly, providing daily
                                                            insights and a rich prayer selection.".</p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block me-auto">
                                                                <div className="text-white float-start me-5px fs-12 fw-500">5.0</div>
                                                                <div className="review-star-icon float-start">
                                                                    <i className="bi bi-star-fill fs-12"></i><i
                                                                        className="bi bi-star-fill fs-12"></i><i
                                                                            className="bi bi-star-fill fs-12"></i><i
                                                                                className="bi bi-star-fill fs-12"></i><i
                                                                                    className="bi bi-star-half fs-12"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="d-inline-block fs-12 bg-medium-gray-transparent fw-600 text-uppercase border-radius-3px ps-15px pe-15px fs-12 lh-26 text-white">
                                                                10 Dec</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide className='review-style-07'>
                                                    <div
                                                        className="d-flex justify-content-center h-100 flex-column border-radius-6px p-12 xs-p-8 bg-oxford-blue">
                                                        <div className="mb-20px">
                                                            <img src={require("../../assets/image/prarthana_home_img16.png")}
                                                                className="rounded-circle w-90px h-90px me-15px" alt="" />
                                                            <div className="d-inline-block align-middle">
                                                                <div className="text-white fw-600 fs-18">Charu Saini</div>
                                                                <div className="lh-26 fs-15 fw-600">Google Review</div>
                                                            </div>
                                                        </div>
                                                        <p className="mb-15px">Praarthana App is my daily companion, enriching my
                                                            spiritual practice with ease.</p>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-inline-block me-auto">
                                                                <div className="text-white float-start me-5px fs-12 fw-500">4.5</div>
                                                                <div className="review-star-icon float-start">
                                                                    <i className="bi bi-star-fill fs-12"></i><i
                                                                        className="bi bi-star-fill fs-12"></i><i
                                                                            className="bi bi-star-fill fs-12"></i><i
                                                                                className="bi bi-star-fill fs-12"></i><i
                                                                                    className="bi bi-star-half fs-12"></i>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="d-inline-block fs-12 bg-medium-gray-transparent fw-600 text-uppercase border-radius-3px ps-15px pe-15px fs-12 lh-26 text-white">
                                                                10 Jan</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="position-relative z-index-2">
                    <div className="container">
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-7 text-center"
                                data-anime='{ "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <div
                                    className="bg-base-color d-inline-block mb-15px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">
                                    Subscription Plan</div>
                                <h4 className="fw-700 text-dark-gray w-65 lg-w-85 mx-auto ls-minus-1px">Choose The Plan That's Right For
                                    You</h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center pricing-table-style-07">
                            <div className="col-lg-6 col-md-12 md-mb-30px">

                                <ul className="nav nav-tabs justify-content-center border-0 text-left"
                                    data-anime='{ "el": "childs", "translateY": [-30, 0], "perspective": [1200,1200], "scale": [1.1, 1], "rotateX": [50, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                    <li className="nav-item mb-30px p-0">
                                        <a data-bs-toggle="tab" href="#tab_four1"
                                            className="nav-link active box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                            <div className="flex-column flex-sm-row d-flex align-items-center">
                                                <div
                                                    className="col-auto align-items-center d-flex me-auto w-100px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                    <div className='gold'>
                                                        <img src="https://praarthana.s3.ap-south-1.amazonaws.com/banner/1700813470537.webp" />
                                                    </div>

                                                </div>
                                                {/* <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 20%</div> */}
                                                <div className="col ms-auto text-end mx-auto">
                                                    <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">From ₹101</h5>
                                                    <span className="d-inline-block align-middle ms-10px opacity-7">For 90 Days</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item mb-30px p-0">
                                        <a data-bs-toggle="tab" href="#tab_four2"
                                            className="nav-link box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                            <div className="flex-column flex-sm-row d-flex align-items-center">
                                                <div
                                                    className="col-auto align-items-center d-flex me-auto w-100px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                    <div className='gold'>
                                                        <img src="https://praarthana.s3.ap-south-1.amazonaws.com/banner/1700813530373.webp" />
                                                    </div>

                                                </div>
                                                {/* <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 30%</div> */}
                                                <div className="col ms-auto text-end mx-auto">
                                                    <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">From ₹251</h5>
                                                    <span className="d-inline-block align-middle ms-10px opacity-7">For 365 Days </span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    {/* <li className="nav-item p-0">
                                    <a data-bs-toggle="tab" href="#tab_four3"
                                        className="nav-link box-shadow-extra-large ps-45px pe-45px pt-35px pb-35px lg-p-5 xs-p-8 border-radius-8px">
                                        <div className="flex-column flex-sm-row d-flex align-items-center">
                                            <div
                                                className="col-auto align-items-center d-flex me-auto w-150px lg-w-140px xs-w-auto mx-auto xs-mb-20px">
                                                <div
                                                    className="icon w-30px h-30px d-flex flex-shrink-0 align-items-center justify-content-center fs-11 border border-2 border-radius-100 me-10px">
                                                    <i className="fa-solid fa-check"></i>
                                                </div>
                                                <div className="fs-20 lg-fs-18 text-dark-gray fw-700 me-15px xs-me-0">Premium</div>
                                            </div>
                                            <div
                                                className="offer d-inline-block border border-color-light-medium-gray fw-600 text-dark-gray text-uppercase border-radius-30px ps-20px pe-20px fs-12 lh-26 xs-mb-20px">
                                                Save 35%</div>
                                            <div className="col ms-auto text-end mx-auto">
                                                <h5 className="fw-700 text-dark-gray d-inline-block align-middle mb-0">$28</h5>
                                                <span className="d-inline-block align-middle ms-10px opacity-7">Per month</span>
                                            </div>
                                        </div>
                                    </a>
                                </li> */}
                                </ul>

                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="tab-content"
                                    data-anime='{ "el": "childs", "translateY": [-30, 0], "perspective": [1200,1200], "scale": [1.05, 1], "rotateX": [5, 0], "opacity": [0,1], "duration": 800, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'>

                                    <div className="tab-pane position-relative fade in active show box-shadow-quadruple-large border-radius-8px pt-40px"
                                        id="tab_four1">
                                        <span
                                            className="text-dark-gray fw-700 mb-15px fs-20 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">Praarthana Gold</span>
                                        <div className="pricing-body overflow-hidden">
                                            <ul className="p-0 list-style-02 text-start">
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>
                                                    Collection of all major Aartis
                                                </li>
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 roman text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Collection of chalisas of all the gods
                                                </li>
                                                <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 roman  text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>A unique collection of bhajans full of devotion.
                                                </li>
                                            </ul>
                                            <div
                                                className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 roman text-dark-gray">
                                                <Link to="/download"
                                                    className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                    this plan</Link>
                                                <span className="fs-15 ms-15px d-inline-block">For 90 Days</span>
                                            </div>
                                        </div>
                                        <span
                                            className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Gold</span>
                                    </div>


                                    <div className="tab-pane position-relative fade in box-shadow-quadruple-large border-radius-8px pt-40px"
                                        id="tab_four2">
                                        <span
                                            className="text-dark-gray fw-700 mb-15px fs-20 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">Praarthana Platinum</span>
                                        <div className="pricing-body overflow-hidden">
                                            <ul className="p-0 list-style-02 text-start">
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i> Collection of all major Aartis
                                                </li>
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Collection of chalisas of all the gods
                                                </li>
                                                <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>A unique collection of bhajans full of devotion.
                                                </li>
                                            </ul>
                                            <div
                                                className="d-block bg-gradient-very-light-gray pt-25px pb-25px ps-45px pe-45px sm-ps-25px sm-pe-25px border-radius-8px">
                                                <Link to="/download"
                                                    className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                    this plan</Link>
                                                <span className="fs-15 ms-15px d-inline-block">For 365 Days</span>
                                            </div>
                                        </div>
                                        <span
                                            className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Platinum</span>
                                    </div>


                                    <div className="tab-pane position-relative fade in box-shadow-quadruple-large border-radius-8px pt-40px"
                                        id="tab_four3">
                                        <span
                                            className="text-dark-gray fw-700 mb-15px fs-24 ls-minus-05px d-inline-block ps-45px pe-45px sm-ps-25px sm-pe-25px">The
                                            most premium plan</span>
                                        <div className="pricing-body overflow-hidden">
                                            <ul className="p-0 list-style-02 text-start">
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Find places
                                                    near hotels and camp
                                                </li>
                                                <li
                                                    className="border-bottom border-color-extra-medium-gray pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Track record
                                                    and reward programs
                                                </li>
                                                <li
                                                    className="pt-15px pb-15px ps-45px pe-45px sm-ps-25px sm-pe-25px fw-500 text-dark-gray">
                                                    <i className="feather icon-feather-check fs-20 text-green me-10px"></i>Shows
                                                    transportation option
                                                </li>
                                            </ul>
                                            <div
                                                className="d-block bg-gradient-very-light-gray pt-25px pb-25px ps-45px pe-45px sm-ps-25px sm-pe-25px border-radius-8px">
                                                <a href="#"
                                                    className="btn btn-medium btn-rounded btn-dark-gray btn-box-shadow fw-700 sm-mb-10px">Join
                                                    this plan</a>
                                                <span className="fs-15 ms-15px d-inline-block">Offer save 35%*</span>
                                            </div>
                                        </div>
                                        <span
                                            className="position-absolute top-25px right-25px bg-white border border-1 border-color-extra-medium-gray box-shadow-large fw-800 text-dark-gray text-uppercase border-radius-3px ps-15px pe-15px fs-12 xs-right-10px xs-top-10px">Premium</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section >

                <section className="pt-0">
                    <div className="container">
                        <div
                            className="row row-cols-1 row-cols-md-4 row-cols-sm-2 position-relative z-index-2 justify-content-center counter-style-02">

                            <div className="col text-center sm-mb-30px">
                                <h2 className="vertical-counter d-inline-flex alt-font text-dark-gray fw-800 mb-0 ls-minus-4px md-ls-minus-2px"
                                    data-to="6350"><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={25000} /></h2>
                                <span className="d-block lh-24">Active users</span>
                            </div>


                            <div className="col text-center sm-mb-30px">
                                <h2 className="vertical-counter d-inline-flex alt-font text-dark-gray fw-800 mb-0 ls-minus-4px md-ls-minus-2px"
                                    data-to="5298"><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={50000} /></h2>
                                <span className="d-block lh-24">Installation</span>
                            </div>


                            <div className="col text-center xs-mb-30px">
                                <h2 className="vertical-counter d-inline-flex alt-font text-dark-gray fw-800 mb-0 ls-minus-4px md-ls-minus-2px"
                                    data-to="5864"><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={300} /></h2>
                                <span className="d-block lh-24">User reviews</span>
                            </div>


                            <div className="col text-center">
                                <h2 className="vertical-counter d-inline-flex alt-font text-dark-gray fw-800 mb-0 ls-minus-4px md-ls-minus-2px"
                                    data-to="7450"><CountUp scrollSpyOnce={true} enableScrollSpy={true} start={0} end={400} /></h2>
                                <span className="d-block lh-24">Monthly Notifications</span>
                            </div>

                        </div>
                    </div>
                </section>

                {/* <section className="overflow-hidden position-relative bg-gradient-very-light-gray py-0 lg-pt-8 lg-pb-8">
                    <div id="particles-style-02" className="h-100 position-absolute left-0px top-0 w-100" data-particle="true"
                        data-particle-options='{"particles": {"number": {"value": 18,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.3,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'>
                    </div>
                    <div className="container">
                        <div
                            className="row align-items-center ps-50px pe-50px lg-px-0 position-relative z-index-1 justify-content-md-center">
                            <div className="col-lg-6 md-mb-50px">
                                <div className="row">
                                    <div className="col-sm-6 xs-mb-30px">
                                        <Parallax speed={5} translateY={['-250px', '200px']} >
                                            <img src={require("../../assets/image/prarthana_home_img17.jpg")}
                                                className="w-100 box-shadow-quadruple-large border-radius-10px"
                                                data-bottom-top="transform: translateY(-250px)"
                                                data-top-bottom="transform: translateY(200px)" alt="" />
                                        </Parallax>
                                    </div>
                                    <div className="col-sm-6">
                                        <Parallax speed={10} translateY={['200px', '-300px']} >
                                            <img src={require("../../assets/image/prarthana_home_img18.jpg")}
                                                className="w-100 box-shadow-quadruple-large border-radius-10px"
                                                data-bottom-top="transform: translateY(200px)"
                                                data-top-bottom="transform: translateY(-300px)" alt="" />
                                        </Parallax>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 text-center text-lg-start">
                                <h4 className="fw-800 text-dark-gray ls-minus-1px">Download the praarthana app now!</h4>
                                <span className="fs-18 w-80 xl-w-100 d-block mb-35px">Discover the ultimate devotional companion with the Praarthana app.
                                    you need for your
                                    spiritual journey.</span>
                                <div className="row pe-20px xl-pe-0 justify-content-center justify-content-lg-start">
                                    <a href="https://apps.apple.com/in/app/mehandipur-balaji/id6450503422" target='_blank' className="col-6 col-lg-6 col-sm-5">
                                        <img src={require("../../assets/images/app-store-white.svg" className="box-shadow-medium-bottom border-radius-6px"
                                            alt="" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.praarthana" target='_blank' className="col-6 col-lg-6 col-sm-5">
                                        <img src={require("../../assets/images/play-store-white.svg" className="box-shadow-medium-bottom border-radius-6px"
                                            alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div >
        </ParallaxProvider>
    )
}
