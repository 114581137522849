import { useGSAP } from '@gsap/react';
import React from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import SplitType from 'split-type'
import { useNavigate } from 'react-router-dom';
import bgImage from '../assets/images/demo-application-page-title-bg.jpg'
export default function NotFound() {


    const navigate = useNavigate()
    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.from(".not_found_list > li", 1, {
            top: "-100vh",
            ease: "bounce.out",
            delay: 1,
            stagger: 0.2,
        });
        gsap.from(".arrow_img > img", 1.5, {
            right: "-100vh",
            ease: "bounce.out",
            delay: 1,
            opacity: 0
        });
        const splitTypes1 = document.querySelectorAll('.reveal-type-3')
        splitTypes1.forEach((char, i) => {



            const text = new SplitType(char, { types: 'chars' })

            gsap.from(text.chars,
                {
                    y: 20,
                    opacity: 0,
                    stagger: 0.03,
                    delay: 2,
                    ease: "bounce.out",
                })
        })


    })


    const goToHome = () => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".arrow_img > img", 0.5, {
            right: "100vh",
            ease: "bounce.out",
            delay: 1,
            opacity: 0,
            onComplete: function () {
                navigate('/')
            }
        });

    }


    return (
        <>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div className='not_found'>
                        <ul className='not_found_list'>
                            <li>4</li>
                            <li>
                                <div className='circle_image'>
                                    <img src={require('../assets/images/circle.png')} />
                                </div>
                            </li>
                            <li>4</li>
                        </ul>
                        <div className='back_to_home'>
                            <div className='arrow_img' onClick={goToHome}>
                                <img src={require('../assets/images/arrow.png')} />
                                <h5 className='reveal-type-3'>Back To Home</h5>
                            </div>
                        </div>
                    </div>
            </section>
           
        </>
    )
}
