import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { get, post } from '../../helpers/helper_api';
import { toast } from 'react-toastify';
import useRazorpay from "react-razorpay";

export default function Checkout() {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(undefined);
    const [planId, setPlanId] = useState(undefined);
    const [couponId, setCouponId] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState("Loading...");
    const [payblePrice, setPayblePrice] = useState(0);
    const [Razorpay, isLoaded] = useRazorpay();

    useEffect(() => {
        if (params?.userId)
            setUserId(params?.userId);
        if (params?.planId)
            setPlanId(params?.planId);
        if (params?.couponId)
            setCouponId(params?.couponId);
    }, [params]);

    useEffect(() => {
        if (userId && planId) {
            setLoading(true);
            post("user/checkout", { userId, planId, couponId })
                .then(res => {
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    } else {
                        toast.error(res?.error);
                        setError(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }, [userId, planId, couponId]);

    useEffect(() => {
        if (data && isLoaded && data?.order) {
            handlePayment();
        }
    }, [data, isLoaded]);

    const handlePayment = async () => {
        let amount = data?.plan?.price * 100;
        if (data?.coupon) {
            let off = (data?.plan?.price * data?.coupon?.value) / 100;
            amount = data?.plan?.price - off;
        }
        setPayblePrice(amount);
        const options = {
            // key: "rzp_test_NKpN1Cv7SvSbsr",
            key: "rzp_live_rOtD8G4dv0mkCe", //old praarthana key
            // key: "rzp_live_8BSp6ultGKoVPi", // tbls live key
            amount: String(amount),
            currency: "INR",
            name: "Praarthana App",
            description: "Subscription",
            image: "https://praarthana.app/logo.png",
            // order_id: String(data?.order?._id),
            handler: function (response) {
                console.log("response from razorPay", response);
                if (response?.razorpay_payment_id) {
                    handlePaymentFinish("Success", response?.razorpay_payment_id, amount)
                } else {
                    handlePaymentFinish("Failed", undefined);
                }
            },
            modal: {
                ondismiss: function () {
                    // console.log("payment cancelled");
                    handlePaymentFinish("Cancelled", undefined);
                    setError("Cancelled");
                }
            },
            prefill: {
                name: data?.user?.name,
                email: data?.user?.email,
                contact: data?.user?.mobile
            },
            notes: {
                address: "B-19, palam vyapar kendra, Gurugram, Haryana 122017",
            },
            theme: {
                color: "#ff0000",
            },
        };
        // console.log("payment option", options);
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            console.log("payment failed", response);
            handlePaymentFinish(response?.error?.code, undefined);
            setError(response?.error?.description);
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };

    const handlePaymentFinish = async (status, txnId, amount) => {
        // console.log("payble price at order", payblePrice);
        let body = {
            "orderId": data?.order?._id,
            txnId,
            status,
            amountPaid: amount ? amount / 100 : undefined
        }
        post('user/order', body)
            .then(res => {
                if (res?.statusCode == 200) {
                    navigate("/payment/success");
                } else {
                    setError(res?.error);
                    toast.error(res?.error);
                    setTimeout(() => {
                        navigate("/payment/failed");
                    }, 3000);
                }
            })
            .catch(error => {
                console.log('error while hitting subscription', error);
                setError("Something Went Wrong!");
                setTimeout(() => {
                    navigate("/payment/failed");
                }, 3000);
            });
    }

    // console.log("payble price", payblePrice);

    return (
        <div>
            <section className="banner-section">
                <div className="banner-content error-container">
                    <div className='error-box'>
                        <h5 style={{ color: "white" }}><b>{error}</b></h5>
                    </div>
                </div>
            </section>
        </div>
    )
}
