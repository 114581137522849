import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {

    return (
        <header>

            <nav class="navbar navbar-expand-lg header-light bg-transparent disable-fixed" data-header-hover="light">
                <div class="container-fluid">
                    <div class="col-auto me-auto me_auto">
                        <Link class="navbar-brand" to="/">
                            <img src={require('../../assets/image/praarthana_red.png')}
                                alt="" class="default-logo" />
                            <img src={require('../../assets/image/praarthana_red.png')}
                                alt="" class="alt-logo" />
                            <img src={require('../../assets/image/praarthana_red.png')}
                                alt="" class="mobile-logo" />
                        </Link>
                    </div>
                    <div class="col-auto menu-order position-static">
                        <button class="navbar-toggler float-start" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-label="Toggle navigation">
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                            <span class="navbar-toggler-line"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">
                                <li class="nav-item"><Link to="/" class="nav-link">Home</Link></li>
                                <li class="nav-item"><Link to="/about" class="nav-link">About</Link></li>
                                <li class="nav-item"><Link to="/pricing" class="nav-link">Pricing</Link></li>
                                <li class="nav-item"><Link to="/blog"
                                    class="nav-link">Blog</Link></li>
                                <li class="nav-item"><Link to="/contact"
                                    class="nav-link">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-auto col-auto xs-ps-0">
                        <div class="header-icon">
                            <div class="header-button">
                                <Link to="/download"
                                    class="btn btn-small btn-rounded with-rounded btn-box-shadow btn-dark-gray text-uppercase-inherit">Download
                                    now<span class="bg-licorice-blue text-white"><i
                                        class="feather icon-feather-arrow-right"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </header>
    )
}
