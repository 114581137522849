import React, { useEffect } from 'react'
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';

export default function Success() {

    const navigate = useNavigate();

    useEffect(() => {
        confettiExplosion({ x: 0.5, y: 0.5 });
        setTimeout(() => {
            navigate("/");
        }, 3000);
    }, []);

    const fire = (particleRatio, opts) => {
        confetti({
            ...opts,
            particleCount: Math.floor(200 * particleRatio),
        });
    };

    const confettiExplosion = (origin) => {
        fire(0.25, { spread: 26, startVelocity: 55, origin });
        fire(0.2, { spread: 60, origin });
        fire(0.35, { spread: 100, decay: 0.91, origin });
        fire(0.1, { spread: 120, startVelocity: 25, decay: 0.92, origin });
        fire(0.1, { spread: 120, startVelocity: 45, origin });
    };

    // window.onload = () => {

    // };


    return (
        <div>
            <section class="banner-section">
                <div class="banner-content">
                    <div className='success_section'>
                        <h4>success</h4>
                    </div>
                </div>
            </section>
            <div className='top'></div>
        </div>
    )
}
