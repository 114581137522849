import React, { useEffect } from 'react'
import bgImage from '../../assets/images/download_banner.jpg'
export default function RefundsandcancellationPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Praarthana App</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Refunds and Cancellation Policy</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='policy_content'>
                <div class="container-fluid">
                    <div class="row">
                        <p>The application is based on a subscription model. Once a person subscribes to the app, the subscription can be cancelled before the next billing date of the subscription only. The subscription amount is not refundable.</p>
                        <h3>Shipping and Delivery Policy</h3>
                        <p>As it is an Online portal, the subscription charges of the application are charged online itself.</p>
                        <p>The Content Delivery is on the platform itself and caters to no physical delivery mechanism.</p>

                        <h3>India Pricing:</h3>
                        <p>• INR 101 - 3 Months</p>
                        <p>• INR 251 - Annual</p>
                    </div>
                </div>

            </section>
        </>
    )
}
