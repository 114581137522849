import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../assets/images/download_banner.jpg'
import bgImage1 from '../../assets/images/demo-application-how-it-works-bg-01.jpg'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { Swiper, SwiperSlide } from 'swiper/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import { EffectCards, Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';

export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <>
      <Helmet>
        <title>Contact | Praarthana App</title>
        <meta name="description" content="Download the Praarthana app and embark on a spiritual journey. Access a rich library of prayers and hymns, receive daily inspiration, and connect with a community of like-minded individuals." />
        <meta name="keywords" content="Praarthana, prayer app, spiritual journey, prayers, hymns, daily inspiration, spiritual growth" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Praarthana App - Your Personal Prayer Companion" />
        <meta property="og:description" content="Experience the spiritual journey with Praarthana. Access a wide range of prayers and hymns, receive daily inspiration, and connect with a supportive community." />
        <meta property="og:url" content="https://www.praarthana.app" />
        <meta property="og:image" content="https://www.praarthana.app/og.png" />
        <meta property="og:location" content="India" />
      </Helmet>
      <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
        <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
        <div class="container">
          <div class="row align-items-center justify-content-center extra-very-small-screen">
            <div class="col-lg-8 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
              <h2 class="mb-10px fw-500">Join the Praarthana App</h2>
              <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Contact Us</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-0">
        <div class="container download-section">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 left-section text-center position-relative animation-float md-mb-30px" data-anime='{ "effect": "slide", "color": "#ffffff", "direction":"lr", "easing": "easeOutQuad", "delay":50}'>
              <img src={require('../../assets/image/prarthna_home_img01.png')} alt="prarthna_home_img01" />
            </div>
            <div class="col-xl-5 offset-xl-1 col-lg-6 col-md-9 position-relative text-center text-lg-start" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
              {/* <div class="bg-base-color d-inline-block mb-20px fw-600 text-white text-uppercase border-radius-30px ps-20px pe-20px fs-12">Immerse your soul in god</div> */}
              <h4 class="fw-700 text-dark-gray w-90 xl-w-100 ls-minus-1px">DIGIFISH MEDIA PRIVATE LIMITED</h4>
              <p class="w-85 xs-w-100 mx-auto mx-lg-0"><img className='w-20px' style={{ marginRight: 8 }} src={require('../../assets/image/pin.png')} /><strong>032, 4th Floor, Emerald Plaza, Sector-65, Gurugram, Haryana-122018</strong></p>
              <div class="row mb-35px pe-10 md-ps-25px md-pe-25px sm-px-0 justify-content-center justify-content-lg-start">
                <div>

                  <span class="d-block">Whatsapp directly?</span>
                  <a href="tel:919990509933" class="text-dark fw-600 mb-15px d-inline-block footer-link-box"><img className='' src={require('../../assets/image/whatsapp1.png')} /> +91 999 050 9933</a>
                  <span class="d-block">Need live support?</span>
                  <a href="mailto:hi@praarthana.app" class="text-dark fw-600 d-inline-block footer-link-box"><img className='' src={require('../../assets/image/email1.png')} /> hi@praarthana.app</a>
                </div>
              </div>
              {/* <div class="fw-500 text-dark-gray"><img src={require('../../assets/image/downloads.jpg')} class="me-10px" alt="" /><span class="fw-800 text-decoration-line-bottom">50000+</span> people already subscribed.</div> */}
            </div>
          </div>
        </div>
      </section>


    </>
  )
}
