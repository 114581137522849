import React, { useEffect } from 'react'

import bgImage from '../../assets/images/download_banner.jpg'

export default function Terms() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section class="page-title-big-typography cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>
                <div id="particles-style-01" class="h-100 position-absolute left-0px top-0 w-100" data-particle="true" data-particle-options='{"particles": {"number": {"value": 12,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#8f76f5", "#a65cef", "#c74ad2", "#e754a4", "#ff6472"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 0.5,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":0.4,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'></div>
                <div class="container">
                    <div class="row align-items-center justify-content-center extra-very-small-screen">
                        <div class="col-lg-8 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
                            <h2 class="mb-10px fw-500">Praarthana App</h2>
                            <h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Terms And Conditions</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className='policy_content'>
                <div class="container-fluid">

                    <h2>TERMS SPECIFIC TO THE APP</h2>
                    <h4>Fees &amp; Subscriptions</h4>
                    <p>
                        Praarthana may charge a subscription fee to Users in consideration for the use of certain aspects of the Praarthana App. Praarthana reserves the right to revisit and revise its subscription fees and amounts at its sole discretion.
                        The Services shall be available to users with different modes of subscription.Praarthana reserves its right to modify the different modes of subscription at its sole discretion. Praarthana shall advise users of any changes to any
                        modes of subscription thereon after. The subscription period for the Services you purchase shall commence on the date on which the Services are made available to you (the “Subscription Term”). Notwithstanding anything in these Terms
                        of Service, upon a termination of this Agreement or a cancellation of any Service on the App, including any subscription, the Services shall only be cancelled at the end of the duration for which the Service has been paid for. If
                        the Services are terminated prior to the end of a duration that has been paid, the Services, including any Praarthana shall remain available until the end of the duration paid for. You will have the right to access and use the
                        Services for the remaining period of the duration in which you cancel your Account. A user can still access Praarthana App for the duration of the remaining period Upon the expiration of the Subscription Term or earlier cancellation
                        of your Account as described in these Terms, your rights to access and use the Services shall terminate and your Account shall be deactivated, except to the extent expressly provided otherwise in these Terms.
                    </p>
                    <h4>Penalties</h4>
                    <p>A user is liable for no penalty post the cancellation of subscription. If the application cancels a subscription of a user due to a suspicious activity, the user would get an email to showcase the reasons for the same.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul>
                        <li>By email: appfinderindia@gmail.com</li>
                    </ul>
                </div>

            </section>
        </>
    )
}
